<!-- eslint-disable no-useless-concat -->
<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
<template>
  <div class="participation-process__wrapper">
    <div
      class="participation-process"
      :class="{ showContent: activeStage !== null }"
    >
      <div class="participation-process__info">
        <div
          v-if="activeStage !== null && showSlider"
          class="participation-process__info--inner"
        >
          <IonIcon
            class="participation-process__info-close"
            icon-name="close"
            @click="closeSlider"
          />
          <BlockParticipationProcessSlider
            v-if="participationWheelData && showSlider && participationWheelDataStatus === 'success'"
            :block="{
              slides: participationWheelData,
            }"
            :active-stage="activeStage"
            @new-target="setNewActiveStage"
          />
        </div>
      </div>
      <svg
        id="Layer_1"
        class="participation-process__wheel"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 2465 2465"
        style="enable-background: new 0 0 2465 2465"
        xml:space="preserve"
      >
        <g class="svg-elements-wrapper" @mouseover="mouseOnSVG = true" @mouseleave=" mouseOnSVG = false; useTarget('');">
          <!--Decoration inner-->
          <g class="inner-decoration">
            <path
              class="st2"
              d="M1232.5,1227.2L1232.5,1227.2L1232.5,1227.2L1232.5,1227.2z"
            />
            <path
              class="st2"
              d="M1232.5,1227.3C1232.5,1227.2,1232.5,1227.2,1232.5,1227.3C1232.5,1227.2,1232.5,1227.2,1232.5,1227.3
              L1232.5,1227.3z"
            />
            <path class="cls-18" d="M1438.5,1095c-28.3-37-68.5-64.4-113.1-77.2l-3.9,13.7c41.7,11.9,79.2,37.5,105.7,72.2,27.4,35.7,41.8,78.5,41.8,123.6s-21.2,105.5-59.6,144c-38.4,38.4-89.6,59.6-144,59.6s-105.5-21.2-144-59.6c-38.4-38.4-59.6-89.6-59.6-144s21.2-105.5,59.6-144c38.4-38.4,89.6-59.6,144-59.6s7.7,0,11.6.3l-18.6,13.8,20.3,3,27.4-20.2-20.3-27.5-20.2-3,14.8,19.9c-5-.3-10-.5-15-.5-58.2,0-112.9,22.7-154,63.8-41.1,41.1-63.8,95.8-63.8,154s22.7,112.9,63.8,154c41.1,41.1,95.8,63.8,154,63.8s112.9-22.7,154-63.8c41.1-41.1,63.8-95.8,63.8-154,0-48.4-15.4-94.1-44.7-132.3Z" />
            <path class="cls-18" d="M1149.9,1223.2h-2.8v8.8h-8.2v-29.3h10.9c8.7,0,12.2,3.4,12.2,10.5s-3.4,10-12.2,10ZM1149.9,1209.3h-2.8v7.7h2.8c2.7,0,3.7-.6,3.7-3.8s-.9-3.9-3.7-3.9Z" />
            <path class="cls-18" d="M1176.8,1230c-1.6,1.5-3.7,2.3-6.2,2.3-4.6,0-7.4-2.7-7.4-8s3.2-7.5,8.5-7.5,2.9.2,4,.5v-.9c0-1.8-1-2.7-3.9-2.7s-4.3.4-6.5,1.2l-.6-5.7c2.5-1.1,5.3-1.6,8.5-1.6,7.5,0,10,3.2,10,9.8v14.4h-5.8l-.5-2ZM1175.7,1222.1c-.5-.2-1.4-.4-2.6-.4-2,0-2.9.8-2.9,2.5s1,2.4,2.5,2.4,3-.9,3-2.2v-2.4Z" />
            <path class="cls-18" d="M1186.8,1232v-23.7l6-.3.7,3.4h.1c1.5-2.6,3.7-3.7,6.1-3.7s2.1.2,2.7.4l-.4,7.1c-.8-.2-1.8-.4-2.8-.4-2.9,0-4.8,1.9-4.8,5.3v11.9h-7.5Z" />
            <path class="cls-18" d="M1213.5,1213.6v11c0,1.5.7,2.2,2.5,2.2s1.4-.1,1.8-.3l.4,5.2c-.9.4-2.7.7-4.5.7-5.2,0-7.7-3-7.7-7.7v-11.2h-2.3v-5.4h2.7l1.2-5.1,5.9-.5v5.6h5v5.4h-5Z" />
            <path class="cls-18" d="M1225.4,1206.4c-2.8,0-4.1-.8-4.1-2.9s1.3-2.9,4.1-2.9,4.1,1,4.1,2.9-1.2,2.9-4.1,2.9ZM1221.5,1232v-23.6l7.7-.4v24h-7.7Z" />
            <path class="cls-18" d="M1249.3,1214.8c-1.1-.3-2.5-.6-3.6-.6-4,0-5.2,1.6-5.2,6s1.9,6.1,5,6.1,2.8-.3,3.9-.7l.5,5.6c-1.4.8-3.4,1.2-6,1.2-7.3,0-11.2-4.1-11.2-12.2s3.4-12.4,11.3-12.4,4.2.4,5.7.9l-.4,6.1Z" />
            <path class="cls-18" d="M1257.1,1206.4c-2.8,0-4.1-.8-4.1-2.9s1.3-2.9,4.1-2.9,4.1,1,4.1,2.9-1.2,2.9-4.1,2.9ZM1253.3,1232v-23.6l7.7-.4v24h-7.7Z" />
            <path class="cls-18" d="M1278,1232.4c-1.9,0-3.6-.4-5-1.6h-.1s0,3.2,0,3.2v4.1h-7.5v-29.8l5.2-.3,1.4,1.9c1.6-1.4,3.8-2.1,6.1-2.1,5.9,0,9.1,3.2,9.1,12.3s-3.7,12.4-9.2,12.4ZM1276,1214.1c-1.3,0-2.5.6-3.1,1.5v9.2c.6.9,1.6,1.6,3,1.6,2.1,0,3.5-1.1,3.5-6.4s-.9-5.9-3.4-5.9Z" />
            <path class="cls-18" d="M1303,1230c-1.6,1.5-3.7,2.3-6.2,2.3-4.6,0-7.4-2.7-7.4-8s3.2-7.5,8.5-7.5,2.9.2,4,.5v-.9c0-1.8-1-2.7-3.9-2.7s-4.3.4-6.5,1.2l-.6-5.7c2.5-1.1,5.3-1.6,8.5-1.6,7.5,0,10,3.2,10,9.8v14.4h-5.8l-.5-2ZM1301.8,1222.1c-.5-.2-1.4-.4-2.6-.4-2,0-2.9.8-2.9,2.5s1,2.4,2.5,2.4,3-.9,3-2.2v-2.4Z" />
            <path class="cls-18" d="M1321.4,1213.6v11c0,1.5.7,2.2,2.5,2.2s1.4-.1,1.8-.3l.4,5.2c-.9.4-2.7.7-4.5.7-5.2,0-7.7-3-7.7-7.7v-11.2h-2.3v-5.4h2.7l1.2-5.1,5.9-.5v5.6h5v5.4h-5Z" />
            <path class="cls-18" d="M1333.3,1206.4c-2.8,0-4.1-.8-4.1-2.9s1.3-2.9,4.1-2.9,4.1,1,4.1,2.9-1.2,2.9-4.1,2.9ZM1329.4,1232v-23.6l7.7-.4v24h-7.7Z" />
            <path class="cls-18" d="M1351.9,1232.5c-7.8,0-11.3-3.6-11.3-12.4s3.5-12.5,11.3-12.5,11.2,3.7,11.2,12.5-3.4,12.4-11.3,12.4ZM1351.9,1226.7c2.6,0,3.5-1.2,3.5-6.5s-.9-6.5-3.5-6.5-3.5,1.2-3.5,6.5.9,6.5,3.5,6.5Z" />
            <path class="cls-18" d="M1379.6,1232v-14.9c0-2.2-1-3-2.9-3s-2.5.5-3.1,1.4v16.6h-7.5v-23.7l5.4-.3,1.1,1.9c1.8-1.4,4-2.1,6.3-2.1,5,0,8.3,3.4,8.3,9.4v14.9h-7.5Z" />
            <path class="cls-18" d="M1225.9,1249.5l3.4,18.1.5,3.1h.1l.4-3.1,3-18.1h8.1l-6.8,29.3h-9.7l-3-17.6-.5-3.2h-.1l-.5,3.2-3,17.6h-9.7l-6.8-29.3h8.5l2.9,18.2.5,3.1h.1l.5-3.1,3.4-18.2h8.8Z" />
            <path class="cls-18" d="M1257.4,1278.8v-14.9c0-2.1-.9-3-2.9-3s-2.5.6-3.1,1.4v16.6h-7.5v-30.1l7.5-.4v7.8c0,0,0,0,0,0,1.8-1.3,3.7-1.7,5.5-1.7,5,0,8,3.5,8,9.4v14.9h-7.5Z" />
            <path class="cls-18" d="M1267.7,1266.7c0-7.6,3.4-12.2,10.9-12.2s10.1,3.6,10.1,11.1,0,2.4-.2,3.4h-13.1c.3,2.9,2,4,5.5,4s4.3-.3,6.3-1l.8,5.8c-2,.9-4.9,1.4-8,1.4-8.5,0-12.4-4.4-12.4-12.5ZM1281.9,1264.3c0-2.7-1.2-3.8-3.2-3.8s-3.1.9-3.3,3.8h6.6Z" />
            <path class="cls-18" d="M1290.8,1266.7c0-7.6,3.4-12.2,10.9-12.2s10.1,3.6,10.1,11.1,0,2.4-.2,3.4h-13.1c.3,2.9,2,4,5.5,4s4.3-.3,6.3-1l.8,5.8c-2,.9-4.9,1.4-8,1.4-8.5,0-12.4-4.4-12.4-12.5ZM1305,1264.3c0-2.7-1.2-3.8-3.2-3.8s-3.1.9-3.3,3.8h6.6Z" />
            <path class="cls-18" d="M1315,1278.8v-30.1l7.6-.4v30.5h-7.6Z" />
          </g>

          <!--Stage 1-->
          <g
            class="stage-main-group"
            :class="{
              active:
                activeMainGroup === 1
                || activeStage === 'target-1-0',
            }"
            @mouseover="setIsActive(1), useTarget()"
            @click="handleClick('target-1-0')"
          >
            <g
              class="stage-group"
              @click="handleClick('target-1-0')"
              @mouseover="useTarget('')"
            >
              <path
                class="st3"
                d="M1858.3,925.7L1858.3,925.7c-45.3-94-111.2-176.6-192.8-241.7S1488.7,573.2,1387,550l-93.4,409.4
            c82.3,18.7,150.6,74.4,186.4,148.6l0,0L1858.3,925.7C1858.3,925.8,1858.3,925.7,1858.3,925.7z"
                @mouseover="useTarget('target-1-0')"
              />
              <path class="cls-8 st1" d="M1453.4,829.8c-3.9,0-7.1-1-9.7-2.6l.5-2.8c2.6,1.7,5.5,2.6,9.1,2.6,5.4,0,8.2-2.6,8.2-7.1,0-9.1-17.5-5.1-17.5-16.4s3.3-9.1,10.6-9.1,6.3.7,8.7,1.8l-.5,2.7c-2.6-1.1-5.1-1.7-8.1-1.7-5.6,0-7.9,2.9-7.9,6.1,0,8.7,17.6,4.6,17.6,16.4,0,6.3-3.7,10.1-11,10.1Z" />
              <path class="cls-8 st1" d="M1475.1,804.6v18.1c0,3.2,1.7,4.5,4.4,4.5s1.7,0,2.3-.4l.2,2.2c-.7.3-2,.5-3.2.5-4.2,0-6.4-2.2-6.4-6.8v-18.2h-4.2v-2.2h4.2l.5-7.1,2.2-.3v7.3h7.1v2.2h-7.1v.2Z" />
              <path class="cls-8 st1" d="M1503.4,825.4c-1.6,2.5-4.7,4.2-8.5,4.2s-8.2-2.4-8.2-8.4,3.1-8,9.7-8,4.9.5,6.7,1.2v-3.3c0-5.1-2.1-6.7-6.9-6.7s-5.3.5-7.6,1.7l-.3-2.4c2.5-1.2,5.3-1.8,8.4-1.8,6.6,0,9.2,2.7,9.2,9.6v17.7h-2.1l-.4-3.8ZM1503,816.6c-.7-.5-3-1.3-6.3-1.3-5.2,0-7.5,2.1-7.5,5.9s2.7,6.1,6.3,6.1,7.4-2.7,7.4-5.3v-5.4Z" />
              <path class="cls-8 st1" d="M1518.5,817c-1.8.4-2.8,1.3-2.8,2.5s.8,1.8,2.6,2.1l6.6.7c6.6.7,8.6,2.7,8.6,6.2s-2.7,7.7-10.9,7.7-10-2.8-10-7.1,1-4,3-5.5c-1.4-.6-2.5-1.8-2.5-3.6s1.3-3.1,3.4-3.8h0c-1.9-1.1-3.2-3.1-3.2-6.4,0-5,2.5-7.9,9.1-7.9s4.8.5,6.5,1.9l4.3-2,.6,1.4-3.5,2.3c.6,1.1,1,2.5,1,4.2,0,5-2.3,7.8-9,7.8s-2.6-.1-3.8-.5ZM1518,824.1c-1.9,1.2-2.9,3-2.9,4.7,0,3.2,2.2,5.1,7.5,5.1s8.4-2,8.4-5-1.2-3.6-6.4-4l-6.6-.8ZM1516,809.8c0,4.5,2.7,5.5,6.3,5.5s6.4-1.9,6.4-5.5-2-5.6-6.4-5.6-6.3,1.9-6.3,5.6Z" />
              <path class="cls-8 st1" d="M1537.7,814.9c0-8.4,3.6-13,10.7-13s10.3,3.7,10.3,12.4,0,1,0,1.4h-18.1c0,8.6,3,11.5,9.4,11.5s5.1-.7,7.4-1.9l.4,2.4c-2.3,1.3-4.9,2.1-8.1,2.1-8.5,0-11.9-4.5-11.9-14.9ZM1556.1,813.7c0-6.8-3.3-9.3-7.7-9.3s-7.6,3.1-7.9,9.3h15.6Z" />
              <path class="cls-8 st1" d="M1584.3,826.8h6.6v-27.9c0,0,0,0,0,0l-7.8,5.3-.4-2.6,9.5-6.7h1.7v31.9h6.4v2.4h-16.1v-2.4Z" />
              <path class="cls-8 st1" d="M1465.3,870.1l7.4,13.7h-11.1l-5-11.9h-3.5v11.9h-9.5v-34.1h13c9.1,0,13.8,3.4,13.8,11.1s-1.9,7.9-5.1,9.3ZM1456.7,857.3h-3.6v7.8h3.5c2.8,0,3.9-.7,3.9-4s-1-3.8-3.8-3.8Z" />
              <path class="cls-8 st1" d="M1472.9,869.7c0-8.9,4-14.2,12.7-14.2s11.8,4.2,11.8,13,0,2.8-.2,3.9h-15.2c.4,3.4,2.4,4.6,6.5,4.6s5-.4,7.3-1.1l1,6.7c-2.3,1-5.7,1.7-9.4,1.7-10,0-14.5-5.2-14.5-14.6ZM1489.4,866.9c0-3.2-1.4-4.4-3.8-4.4s-3.6,1-3.9,4.4h7.7Z" />
              <path class="cls-8 st1" d="M1508.6,872.7c-.3.2-.5.6-.5.9,0,.6.5,1,1.5,1l6.5.3c6.4.3,9.1,2.8,9.1,7.3s-4,9.2-13.3,9.2-11.7-3.1-11.7-7.3.6-3,1.9-4.2c-1.2-.9-2-2.3-2-4.1s1-3.8,3.4-5c-1.9-1.3-3-3.4-3-6.5,0-5,3-8.8,11.1-8.8s5,.4,7,1.4l4.8-1.4,1.8,4-2.8,2.3c.2.7.3,1.6.3,2.5,0,5.4-3.3,8.6-11.1,8.6s-2-.1-3-.2ZM1508.2,881.6c-.4.5-.6,1-.6,1.5,0,1.5,1.2,2.1,4.1,2.1,4.6,0,5.7-.6,5.7-1.7s-.5-1.3-2.8-1.5l-6.4-.4ZM1509.1,864.5c0,2.1.9,2.7,2.4,2.7s2.8-.6,2.8-2.7-.7-2.7-2.6-2.7-2.6.7-2.6,2.7Z" />
              <path class="cls-8 st1" d="M1533,853.9c-3.3,0-4.8-.9-4.8-3.4s1.5-3.4,4.8-3.4,4.7,1.2,4.7,3.4-1.4,3.4-4.7,3.4ZM1528.5,883.8v-27.6l9-.4v28h-9Z" />
              <path class="cls-8 st1" d="M1550.9,884.3c-3.4,0-6.4-.5-8.8-1.7l.9-6.9c2.4,1.2,5.7,1.8,7.9,1.8s3-.8,3-1.9c0-3.5-12.1-1.1-12.1-11.1s3.5-9,11.1-9,5.7.6,8.1,1.5l-.6,6.8c-2.3-.9-5.6-1.3-7.6-1.3s-2.5.6-2.5,1.6c0,3.3,11.9,1.1,11.9,10.6-.2,5.8-3.4,9.6-11.3,9.6Z" />
              <path class="cls-8 st1" d="M1574.4,862.3v12.8c0,1.8.9,2.5,2.9,2.5s1.6,0,2.1-.3l.5,6.1c-1,.5-3.1.9-5.3.9-6.1,0-9-3.5-9-9v-13h-2.6v-6.3h3.1l1.4-5.9,6.9-.6v6.5h5.8v6.3h-5.8Z" />
              <path class="cls-8 st1" d="M1583.1,883.8v-27.6l7-.3.8,4h0c1.7-3,4.3-4.4,7.1-4.4s2.4.2,3.1.5l-.5,8.3c-.9-.3-2-.5-3.2-.5-3.4,0-5.6,2.2-5.6,6.1v13.9h-8.8Z" />
              <path class="cls-8 st1" d="M1618.2,881.5c-1.8,1.7-4.3,2.7-7.2,2.7-5.4,0-8.7-3.1-8.7-9.3s3.7-8.8,9.9-8.8,3.4.2,4.7.6v-1c0-2-1.2-3.2-4.6-3.2s-5,.5-7.6,1.4l-.7-6.6c2.9-1.2,6.2-1.8,9.9-1.8,8.8,0,11.6,3.8,11.6,11.5v16.8h-6.7l-.6-2.3ZM1616.9,872.3c-.6-.2-1.6-.4-3-.4-2.3,0-3.4,1-3.4,3s1.1,2.8,2.9,2.8,3.5-1,3.5-2.5v-2.9Z" />
              <path class="cls-8 st1" d="M1639.7,862.3v12.8c0,1.8.9,2.5,2.9,2.5s1.6,0,2.1-.3l.5,6.1c-1,.5-3.1.9-5.3.9-6.1,0-9-3.5-9-9v-13h-2.6v-6.3h3.1l1.4-5.9,6.9-.6v6.5h5.8v6.3h-5.8Z" />
              <path class="cls-8 st1" d="M1653.6,853.9c-3.3,0-4.8-.9-4.8-3.4s1.5-3.4,4.8-3.4,4.7,1.2,4.7,3.4-1.4,3.4-4.7,3.4ZM1649.1,883.8v-27.6l9-.4v28h-9Z" />
              <path class="cls-8 st1" d="M1675.3,884.4c-9.1,0-13.1-4.2-13.1-14.4s4.1-14.6,13.2-14.6,13.1,4.4,13.1,14.6-4,14.4-13.2,14.4ZM1675.3,877.6c3,0,4-1.5,4-7.6s-1-7.6-4-7.6-4.1,1.4-4.1,7.6,1.1,7.6,4.1,7.6Z" />
              <path class="cls-8 st1" d="M1707.6,883.8v-17.4c0-2.5-1.1-3.5-3.4-3.5s-2.9.6-3.6,1.6v19.3h-8.7v-27.7l6.3-.4,1.2,2.2c2-1.6,4.6-2.5,7.4-2.5,5.9,0,9.6,3.9,9.6,11v17.4h-8.8Z" />
            </g>
          </g>

          <!--Stage 2-->
          <g
            class="stage-main-group"
            :class="{
              active:
                activeMainGroup === 2
                || activeStage === 'target-2-0',
            }"
            @mouseover="setIsActive(2)"
          >
            <g
              class="stage-group"
              @click="handleClick('target-2-0')"
              @mouseover="useTarget('')"
            >
              <path
                class="st8"
                d="M1858.3,925.8L1480.1,1108c17.4,36.1,27.2,76.5,27.2,119.3c0,42.7-9.7,83.1-27.1,119.2l0,0l378.3,182.1
            c0,0,0,0,0-0.1c0,0,0,0,0-0.1c45.2-94,68.7-197,68.7-301.2C1927.2,1122.9,1903.6,1019.8,1858.3,925.8z"
              />
              <path class="cls-8 st2" d="M1622.9,1211.3c-3.9,0-7.1-1-9.7-2.6l.5-2.8c2.6,1.7,5.5,2.6,9.1,2.6,5.4,0,8.2-2.6,8.2-7.1,0-9.1-17.5-5.1-17.5-16.4s3.3-9.1,10.6-9.1,6.3.7,8.7,1.8l-.5,2.7c-2.6-1.1-5.1-1.7-8.1-1.7-5.6,0-7.9,2.9-7.9,6.1,0,8.7,17.6,4.6,17.6,16.4,0,6.3-3.7,10.1-11,10.1Z" />
              <path class="cls-8 st2" d="M1644.6,1186.1v18.1c0,3.2,1.7,4.5,4.4,4.5s1.7,0,2.3-.4l.2,2.2c-.7.3-2,.5-3.2.5-4.2,0-6.4-2.2-6.4-6.8v-18.2h-4.2v-2.2h4.2l.5-7.1,2.2-.3v7.3h7.1v2.2h-7.1v.2Z" />
              <path class="cls-8 st2" d="M1672.9,1206.9c-1.6,2.5-4.7,4.2-8.5,4.2s-8.2-2.4-8.2-8.4,3.1-8,9.7-8,4.9.5,6.7,1.2v-3.3c0-5.1-2.1-6.7-6.9-6.7s-5.3.5-7.6,1.7l-.3-2.4c2.5-1.2,5.3-1.8,8.4-1.8,6.6,0,9.2,2.7,9.2,9.6v17.7h-2.1l-.4-3.8ZM1672.5,1198c-.7-.5-3-1.3-6.3-1.3-5.2,0-7.5,2.1-7.5,5.9s2.7,6.1,6.3,6.1,7.4-2.7,7.4-5.3v-5.4Z" />
              <path class="cls-8 st2" d="M1688,1198.5c-1.8.4-2.8,1.3-2.8,2.5s.8,1.8,2.6,2.1l6.6.7c6.6.7,8.6,2.7,8.6,6.2s-2.7,7.7-10.9,7.7-10-2.8-10-7.1,1-4,3-5.5c-1.4-.6-2.5-1.8-2.5-3.6s1.3-3.1,3.4-3.8h0c-1.9-1.1-3.2-3.1-3.2-6.4,0-5,2.5-7.9,9.1-7.9s4.8.5,6.5,1.9l4.3-2,.6,1.4-3.5,2.3c.6,1.1,1,2.5,1,4.2,0,5-2.3,7.8-9,7.8s-2.6,0-3.8-.5ZM1687.5,1205.6c-1.9,1.2-2.9,3-2.9,4.7,0,3.2,2.2,5.1,7.5,5.1s8.4-2,8.4-5-1.2-3.6-6.4-4l-6.6-.8ZM1685.5,1191.3c0,4.5,2.7,5.5,6.3,5.5s6.4-1.9,6.4-5.5-2-5.6-6.4-5.6-6.3,1.9-6.3,5.6Z" />
              <path class="cls-8 st2" d="M1707.1,1196.4c0-8.4,3.6-13,10.7-13s10.3,3.7,10.3,12.4,0,1,0,1.4h-18.1c0,8.6,3,11.5,9.4,11.5s5.1-.7,7.4-1.9l.4,2.4c-2.3,1.3-4.9,2.1-8.1,2.1-8.5-.1-11.9-4.6-11.9-14.9ZM1725.6,1195.2c0-6.8-3.3-9.3-7.7-9.3s-7.6,3.1-7.9,9.3h15.6Z" />
              <path class="cls-8 st2" d="M1753.4,1207.8h17.6c0,0,0,2.9,0,2.9h-21.5v-2.1c9.1-8.5,18.5-16.1,18.5-23.1s-2.4-6.6-8.3-6.6-6.7,1.5-9.1,3.5l-.9-2.5c2.5-2.2,6.2-3.8,10.4-3.8,7,0,10.8,3.4,10.8,9.1-.1,7.5-7.3,14.2-17.4,22.6Z" />
              <path class="cls-8 st2" d="M1632.6,1259.4h-10.6l-1.5,5.9h-10.1l11.3-34.1h11.4l11.5,34.1h-10.6l-1.4-5.9ZM1626.4,1242.3l-2.5,9.6h6.7l-2.4-9.5-.8-3.6h0l-.9,3.5Z" />
              <path class="cls-8 st2" d="M1661.5,1265.7c-2.2,0-4.2-.5-5.8-1.9h0v3.7c0,0,0,4.8,0,4.8h-8.8v-34.8l6.1-.4,1.6,2.2c1.9-1.6,4.5-2.5,7.1-2.5,6.9,0,10.6,3.8,10.6,14.3-.1,9.8-4.3,14.6-10.8,14.6ZM1659.2,1244.4c-1.5,0-2.9.7-3.6,1.8v10.7c.7,1.1,1.9,1.9,3.5,1.9,2.5,0,4-1.2,4-7.5,0-5.5-1-6.9-3.9-6.9Z" />
              <path class="cls-8 st2" d="M1690.3,1265.7c-2.2,0-4.2-.5-5.8-1.9h0v3.7c0,0,0,4.8,0,4.8h-8.8v-34.8l6.1-.4,1.6,2.2c1.9-1.6,4.5-2.5,7.1-2.5,6.9,0,10.6,3.8,10.6,14.3s-4.3,14.6-10.8,14.6ZM1688.1,1244.4c-1.5,0-2.9.7-3.6,1.8v10.7c.7,1.1,1.9,1.9,3.5,1.9,2.5,0,4-1.2,4-7.5s-1-6.9-3.9-6.9Z" />
              <path class="cls-8 st2" d="M1704.9,1265.3v-35.2l8.9-.4v35.6h-8.9Z" />
              <path class="cls-8 st2" d="M1723.7,1235.4c-3.3,0-4.8-.9-4.8-3.4s1.5-3.4,4.8-3.4,4.7,1.2,4.7,3.4-1.4,3.4-4.7,3.4ZM1719.2,1265.3v-27.6l9-.4v28h-9Z" />
              <path class="cls-8 st2" d="M1751.6,1245.2c-1.3-.4-2.9-.7-4.2-.7-4.7,0-6,1.8-6,7s2.2,7.1,5.9,7.1,3.2-.3,4.5-.8l.6,6.6c-1.6.9-4,1.5-7.1,1.5-8.5,0-13.1-4.8-13.1-14.3s4-14.5,13.2-14.5,4.9.5,6.6,1.1l-.4,7Z" />
              <path class="cls-8 st2" d="M1770.3,1263c-1.8,1.7-4.3,2.7-7.2,2.7-5.4,0-8.7-3.1-8.7-9.3s3.7-8.8,9.9-8.8,3.4.2,4.7.6v-1c0-2-1.2-3.2-4.6-3.2s-5,.5-7.6,1.4l-.7-6.6c2.9-1.2,6.2-1.8,9.9-1.8,8.8,0,11.6,3.8,11.6,11.5v16.8h-6.7l-.6-2.3ZM1769,1253.8c-.6-.2-1.6-.4-3-.4-2.3,0-3.4,1-3.4,3s1.1,2.8,2.9,2.8,3.5-1,3.5-2.5v-2.9Z" />
              <path class="cls-8 st2" d="M1791.7,1243.8v12.8c0,1.8.9,2.5,2.9,2.5s1.6,0,2.1-.3l.5,6.1c-1,.5-3.1.9-5.3.9-6.1,0-9-3.5-9-9v-13h-2.6v-6.3h3.1l1.4-5.9,6.9-.6v6.5h5.8v6.3h-5.8Z" />
              <path class="cls-8 st2" d="M1805.6,1235.4c-3.3,0-4.8-.9-4.8-3.4s1.5-3.4,4.8-3.4,4.7,1.2,4.7,3.4c0,2.3-1.4,3.4-4.7,3.4ZM1801.1,1265.3v-27.6l9-.4v28h-9Z" />
              <path class="cls-8 st2" d="M1827.3,1265.9c-9.1,0-13.1-4.2-13.1-14.4s4.1-14.6,13.2-14.6,13.1,4.4,13.1,14.6-4,14.4-13.2,14.4ZM1827.4,1259.1c3,0,4-1.5,4-7.6s-1-7.6-4-7.6-4.1,1.4-4.1,7.6,1.1,7.6,4.1,7.6Z" />
              <path class="cls-8 st2" d="M1859.7,1265.3v-17.4c0-2.5-1.1-3.5-3.4-3.5s-2.9.6-3.6,1.6v19.3h-8.7v-27.7l6.3-.4,1.2,2.2c2-1.6,4.6-2.5,7.4-2.5,5.9,0,9.6,3.9,9.6,11v17.4h-8.8Z" />
            </g>
            <g class="target-group">
              <g
                id="target-2-1"
                class="target-item"
                :class="{ active: activeStage === 'target-2-1' }"
                @mouseover="useTarget('target-2-1')"
              >
                <path
                  class="st9"
                  d="M1868,1227.2h114c0-56.1-6.3-112-18.7-166.7c-12.5-54.7-31-107.9-55.4-158.5L1805,951.5
            C1845.8,1036.1,1868.1,1130.1,1868,1227.2z"
                />
                <g>
                  <path
                    class="st10"
                    d="M1898.7,1084.3L1898.7,1084.3l5.6,0.1l-0.1,3.8H1893v-2.8c3.8-3.9,6.7-6.4,6.7-8.5c0-1-0.6-1.5-2.1-1.5
            c-1.3,0-2.6,0.6-3.8,1.3l-1.1-3.5c1.5-1,3.4-1.7,5.7-1.7c4,0,5.9,2.1,5.9,5C1904.4,1079.2,1902.1,1081.5,1898.7,1084.3z"
                  />
                  <path
                    class="st10"
                    d="M1908.6,1088.4c-1.7,0-2.4-0.6-2.4-2.2c0-1.4,0.7-2.2,2.4-2.2s2.4,0.8,2.4,2.2
            S1910.3,1088.4,1908.6,1088.4z"
                  />
                  <path
                    class="st10"
                    d="M1915.1,1085.1h2.1v-8.3l-2.7,1.5l-0.4-3.6l5-3.1h2.7v13.5h2.1v3.1h-8.9v-3.1H1915.1z"
                  />
                </g>
              </g>
              <g
                id="target-2-2"
                class="target-item"
                :class="{ active: activeStage === 'target-2-2' }"
                @mouseover="useTarget('target-2-2')"
              >
                <path
                  class="st11"
                  d="M1868,1227.2c-0.1,46.7-5.3,94-16.1,141.4v0.1c-10.8,47.3-26.7,92.2-46.9,134.2l102.7,49.5
                            c24.3-50.5,42.9-103.6,55.4-158.2v-0.1c12.5-54.7,18.8-110.6,18.9-166.7v-0.1L1868,1227.2z"
                />
                <g>
                  <path
                    class="st10"
                    d="M1897.6,1382L1897.6,1382l5.6,0.1l-0.1,3.8h-11.2v-2.8c3.8-3.9,6.7-6.4,6.7-8.5c0-1-0.6-1.5-2.1-1.5
            c-1.3,0-2.6,0.6-3.8,1.3l-1.1-3.5c1.5-1,3.4-1.7,5.7-1.7c4,0,5.9,2.1,5.9,5C1903.3,1376.9,1901,1379.1,1897.6,1382z"
                  />
                  <path
                    class="st10"
                    d="M1907.6,1386.1c-1.7,0-2.4-0.6-2.4-2.2c0-1.4,0.7-2.2,2.4-2.2s2.4,0.8,2.4,2.2
            C1909.9,1385.3,1909.2,1386.1,1907.6,1386.1z"
                  />
                  <path
                    class="st10"
                    d="M1917.9,1382L1917.9,1382l5.6,0.1l-0.1,3.8h-11.2v-2.8c3.8-3.9,6.7-6.4,6.7-8.5c0-1-0.6-1.5-2.1-1.5
            c-1.3,0-2.6,0.6-3.8,1.3l-1.1-3.5c1.5-1,3.4-1.7,5.7-1.7c4,0,5.9,2.1,5.9,5C1923.5,1376.9,1921.2,1379.1,1917.9,1382z"
                  />
                </g>
              </g>
            </g>
          </g>

          <!-- Stage 3 -->
          <g
            class="stage-main-group"
            :class="{
              active:
                activeMainGroup === 3
                || activeStage === 'target-3-0',
            }"
            @mouseover="setIsActive(3)"
            @click="handleClick('target-3-0')"
          >
            <g class="stage-group">
              <path
                class="st5"
                d="M1293.7,1495.1l93.5,409.3c101.7-23.2,196.9-69.1,278.5-134.2s147.4-147.7,192.7-241.7l-378.3-182.1
            C1444.3,1420.7,1376,1476.4,1293.7,1495.1z"
              />
              <path class="cls-6 st3" d="M1472.3,1581.9c-3.9,0-7.1-1-9.7-2.6l.5-2.8c2.6,1.7,5.5,2.6,9.1,2.6,5.4,0,8.2-2.6,8.2-7.1,0-9.1-17.5-5.1-17.5-16.4s3.3-9.1,10.6-9.1,6.3.7,8.7,1.8l-.5,2.7c-2.6-1.1-5.1-1.7-8.1-1.7-5.6,0-7.9,2.9-7.9,6.1,0,8.7,17.6,4.6,17.6,16.4,0,6.3-3.7,10.1-11,10.1Z" />
              <path class="cls-6 st3" d="M1494.1,1556.7v18.1c0,3.2,1.7,4.5,4.4,4.5s1.7-.1,2.3-.4l.2,2.2c-.7.3-2,.5-3.2.5-4.2,0-6.4-2.2-6.4-6.8v-18.2h-4.2v-2.2h4.2l.5-7.1,2.2-.3v7.3h7.1v2.2h-7.1v.2Z" />
              <path class="cls-6 st3" d="M1522.3,1577.5c-1.6,2.5-4.7,4.2-8.5,4.2s-8.2-2.4-8.2-8.4,3.1-8,9.7-8,4.9.5,6.7,1.2v-3.3c0-5.1-2.1-6.7-6.9-6.7s-5.3.5-7.6,1.7l-.3-2.4c2.5-1.2,5.3-1.8,8.4-1.8,6.6,0,9.2,2.7,9.2,9.6v17.7h-2.1l-.4-3.8ZM1522,1568.7c-.7-.5-3-1.3-6.3-1.3-5.2,0-7.5,2.1-7.5,5.9s2.7,6.1,6.3,6.1,7.4-2.7,7.4-5.3v-5.4Z" />
              <path class="cls-6 st3" d="M1537.4,1569.1c-1.8.4-2.8,1.3-2.8,2.5s.8,1.8,2.6,2.1l6.6.7c6.6.7,8.6,2.7,8.6,6.2s-2.7,7.7-10.9,7.7-10-2.8-10-7.1,1-4,3-5.5c-1.4-.6-2.5-1.8-2.5-3.6s1.3-3.1,3.4-3.8h0c-1.9-1.1-3.2-3.1-3.2-6.4,0-5,2.5-7.9,9.1-7.9s4.8.5,6.5,1.9l4.3-2,.6,1.4-3.5,2.3c.6,1.1,1,2.5,1,4.2,0,5-2.3,7.8-9,7.8s-2.5,0-3.8-.5ZM1536.9,1576.2c-1.9,1.2-2.9,3-2.9,4.7,0,3.2,2.2,5.1,7.5,5.1s8.4-2,8.4-5-1.2-3.6-6.4-4l-6.6-.8ZM1534.9,1561.9c0,4.5,2.7,5.5,6.3,5.5s6.4-1.9,6.4-5.5-2-5.6-6.4-5.6-6.3,1.9-6.3,5.6Z" />
              <path class="cls-6 st3" d="M1556.6,1567c0-8.4,3.6-13,10.7-13s10.3,3.7,10.3,12.4,0,1,0,1.4h-18.1c0,8.6,3,11.5,9.4,11.5s5.1-.7,7.4-1.9l.4,2.4c-2.3,1.3-4.9,2.1-8.1,2.1-8.5,0-11.9-4.6-11.9-14.9ZM1575,1565.8c0-6.8-3.3-9.3-7.7-9.3s-7.6,3.1-7.9,9.3h15.6Z" />
              <path class="cls-6 st3" d="M1608.8,1581.7c-4.7,0-7.8-1.3-10.2-2.8l.7-2.5c2.6,1.7,5.6,2.7,9.5,2.7,6.4,0,9.3-3.4,9.3-8.1s-3.4-6.8-11.9-6.8v-2.3c7.9,0,11-2.4,11-6.6s-3.2-5.8-8.1-5.8-6.7,1.3-9.2,3.5l-.9-2.3c2.3-2.1,5.9-3.8,10.2-3.8,6.6,0,10.7,2.9,10.7,8.3s-2.7,6.8-6.4,7.8h0c4.8,1.1,7.4,3.5,7.4,8.3,0,6.1-3.8,10.4-12.1,10.4Z" />
              <path class="cls-6 st3" d="M1486.8,1627.9l-.2,8h-24.1v-34.1h23.5v8.1c0,0-14,0-14,0v5h11.6v7.3h-11.6v5.8h14.8Z" />
              <path class="cls-6 st3" d="M1517.8,1608.1l-9.7,27.8h-10.1l-9.6-27.8h9.5l4.3,16.1.9,3.5h0l.9-3.5,4.3-16.1h9.4Z" />
              <path class="cls-6 st3" d="M1534.2,1633.6c-1.8,1.7-4.3,2.7-7.2,2.7-5.4,0-8.7-3.1-8.7-9.3s3.7-8.8,9.9-8.8,3.4.2,4.7.6v-1c0-2-1.2-3.2-4.6-3.2s-5,.5-7.6,1.4l-.7-6.6c2.9-1.2,6.2-1.8,9.9-1.8,8.8,0,11.6,3.8,11.6,11.5v16.8h-6.7l-.6-2.3ZM1532.9,1624.4c-.6-.2-1.6-.4-3-.4-2.3,0-3.4,1-3.4,3s1.1,2.8,2.9,2.8,3.5-1,3.5-2.5v-2.9Z" />
              <path class="cls-6 st3" d="M1546.2,1635.9v-35.2l8.9-.4v35.6h-8.9Z" />
              <path class="cls-6 st3" d="M1584.2,1625.7c0,7-4,10.6-12.3,10.6s-12.2-3.8-12.2-10.6v-17.7h8.7v17.6c0,2.2,1,3.3,3.5,3.3s3.5-1,3.5-3.3v-17.6h8.8v17.7Z" />
              <path class="cls-6 st3" d="M1603.4,1633.6c-1.8,1.7-4.3,2.7-7.2,2.7-5.4,0-8.7-3.1-8.7-9.3s3.7-8.8,9.9-8.8,3.4.2,4.7.6v-1c0-2-1.2-3.2-4.6-3.2s-5,.5-7.6,1.4l-.7-6.6c2.9-1.2,6.2-1.8,9.9-1.8,8.8,0,11.6,3.8,11.6,11.5v16.8h-6.7l-.6-2.3ZM1602.1,1624.4c-.6-.2-1.6-.4-3-.4-2.3,0-3.4,1-3.4,3s1.1,2.8,2.9,2.8,3.5-1,3.5-2.5v-2.9Z" />
              <path class="cls-6 st3" d="M1624.9,1614.4v12.8c0,1.8.9,2.5,2.9,2.5s1.6,0,2.1-.3l.5,6.1c-1,.5-3.1.9-5.3.9-6.1,0-9-3.5-9-9v-13h-2.6v-6.3h3.1l1.4-5.9,6.9-.6v6.5h5.8v6.3h-5.8Z" />
              <path class="cls-6 st3" d="M1638.8,1606c-3.3,0-4.8-.9-4.8-3.4s1.5-3.4,4.8-3.4,4.7,1.2,4.7,3.4-1.4,3.4-4.7,3.4ZM1634.3,1635.9v-27.6l9-.4v28h-9Z" />
              <path class="cls-6 st3" d="M1660.5,1636.5c-9.1,0-13.1-4.2-13.1-14.4s4.1-14.6,13.2-14.6,13.1,4.4,13.1,14.6-4,14.4-13.2,14.4ZM1660.5,1629.7c3,0,4-1.5,4-7.6s-1-7.6-4-7.6-4.1,1.4-4.1,7.6,1.1,7.6,4.1,7.6Z" />
              <path class="cls-6 st3" d="M1692.8,1635.9v-17.4c0-2.5-1.1-3.5-3.4-3.5s-2.9.6-3.6,1.6v19.3h-8.7v-27.7l6.3-.4,1.2,2.2c2-1.6,4.6-2.5,7.4-2.5,5.9,0,9.6,3.9,9.6,11v17.4h-8.8Z" />
            </g>
          </g>

          <!-- Stage 4 -->
          <g
            class="stage-main-group"
            :class="{ active: activeMainGroup === 4 }"
            @mouseover="setIsActive(4)"
          >
            <g
              id="fg-stage-4-0"
              class="funding-group"
              :class="{ active: activeStage === 'target-4-0' }"
              @click="handleClick('target-4-0')"
            >
              <g>
                <path
                  class="st23"
                  d="M806.6,2116.7c202.1,93.8,430.2,116.1,646.7,63.4l-22.1-90.9l-34.4-141.2c-29.7,6-59.6,10.4-89.8,13.1h-0.1
                            c-40.1,3.6-80.6,4.2-120.9,1.8c-79.8-4.8-159.4-21.4-235.9-50.2l0,0c-64.5-24.3-126.9-57.3-185.4-99.1l-88.9,114.9l-57.1,73.9
                            C676.9,2047.4,739.9,2085.7,806.6,2116.7z"
                />
              </g>

              <g>
                <g>
                  <path
                    class="st10"
                    d="M972.4,2012.2l-1.6,4.8l9.7,3.2l-2.2,6.5l-9.6-3.2l-3.4,10.3l-7.9-2.6l9.4-28.5l19.6,6.5l-2.2,6.9
            L972.4,2012.2z"
                  />
                  <path
                    class="st10"
                    d="M1002.2,2035.9c-1.7,5.9-6,8-13,5.9s-9.4-6.2-7.7-12l4.4-14.9l7.4,2.2l-4.4,14.8c-0.6,1.9,0.1,3.1,2.2,3.7
                                c2,0.6,3.2,0,3.7-1.9l4.4-14.8l7.4,2.2L1002.2,2035.9z"
                  />
                  <path
                    class="st10"
                    d="M1016.5,2048.9l3.8-14.8c0.6-2.2-0.2-3.3-2.1-3.8c-1.2-0.3-2.6-0.1-3.4,0.6l-4.3,16.5l-7.4-1.9l6.1-23.5
            l5.4,1.1l0.6,2.1c2.1-0.9,4.5-1.1,6.8-0.5c5,1.3,7.3,5.5,5.8,11.5l-3.8,14.8L1016.5,2048.9z"
                  />
                  <path
                    class="st10"
                    d="M1042.4,2055l-0.2-2c-1.9,1.1-4,1.6-6.7,1c-6-1.3-8.2-6.1-6.3-14.6c1.7-7.8,5.9-11.6,11.7-10.3
                                c2,0.4,3.7,1.5,4.9,3l0.1-0.1l0.6-3.1l1-4.5l7.6,1.3l-6.8,30.5L1042.4,2055z M1044.4,2038.1c-0.4-0.9-1.4-1.7-2.7-2
                                c-2.5-0.6-3.8,0.6-4.8,5.3c-1.1,5.1-0.7,6.6,2.1,7.2c1.5,0.3,2.7-0.2,3.4-0.9L1044.4,2038.1z"
                  />
                  <path
                    class="st10"
                    d="M1052.9,2057.2l4.6-23.8l7.8,1.2l-4.7,24.1L1052.9,2057.2z M1061.8,2032.1c-2.8-0.6-4-1.6-3.5-3.7
            c0.4-1.9,1.9-2.7,4.7-2.1c2.9,0.6,3.9,1.8,3.5,3.7C1066.1,2032,1064.7,2032.7,1061.8,2032.1z"
                  />
                  <path
                    class="st10"
                    d="M1078.6,2061.9l2.5-15.1c0.4-2.2-0.5-3.2-2.4-3.6c-1.3-0.2-2.6,0.1-3.4,0.8l-2.8,16.8l-7.6-1.3l4-24
            l5.5,0.6l0.7,2.1c2-1.1,4.4-1.5,6.8-1.1c5.1,0.9,7.8,4.8,6.8,10.9l-2.5,15.1L1078.6,2061.9z"
                  />
                  <path
                    class="st10"
                    d="M1097.8,2054.9c-0.3,0.2-0.5,0.5-0.5,0.7c-0.1,0.5,0.3,0.9,1.2,1l5.7,1c5.6,1,7.6,3.5,7,7.4
                                c-0.7,5-4.6,7.6-12.7,6.5c-7.2-1-9.8-4.1-9.3-7.7c0.2-1.4,0.9-2.6,2.2-3.4c-0.9-0.9-1.5-2.2-1.3-3.8c0.2-1.7,1.3-3.2,3.6-4
                                c-1.5-1.4-2.2-3.3-1.9-6c0.6-4.4,3.7-7.3,10.7-6.3c2.1,0.3,4.3,0.9,5.9,2.1l4.3-0.7l1.1,3.7l-2.7,1.7c0.1,0.7,0.1,1.4-0.1,2.2
                                c-0.6,4.7-3.9,7.1-10.6,6.2C1099.5,2055.3,1098.7,2055.1,1097.8,2054.9z M1096.4,2062.6c-0.4,0.4-0.7,0.8-0.7,1.2
                                c-0.2,1.3,0.8,2,3.4,2.3c4,0.5,5.1,0.1,5.2-0.8c0.1-0.8-0.3-1.2-2.3-1.6L1096.4,2062.6z M1099.2,2047.8
                                c-0.2,1.8,0.4,2.4,1.8,2.6c1.9,0.3,2.5-0.2,2.7-2c0.2-1.7-0.3-2.4-1.9-2.6C1100.1,2045.5,1099.4,2046.1,1099.2,2047.8z"
                  />
                  <path
                    class="st6"
                    d="M1127.3,2062.6l3.9,0.3l1.3-14.9l-5.1,2.2l-0.2-6.5l9.6-4.9l4.9,0.4l-2.1,24.3l3.9,0.3l-0.5,5.6l-16-1.4
            L1127.3,2062.6z"
                  />
                </g>
                <g>
                  <path
                    class="st6"
                    d="M864.2,2046.5l-8.8-4.4l13.4-26.9l8.8,4.4c7.9,3.9,9.4,9.4,4.7,18.9
            C877.3,2048.3,871.8,2050.3,864.2,2046.5z M876.3,2021.9l-6.3-3.2l-11.1,22.3l6.4,3.2c5.9,2.9,10,2,14.5-6.9
            C883.9,2028.8,882.5,2025,876.3,2021.9z"
                  />
                  <path
                    class="st6"
                    d="M885,2042.6c3.1-6.7,7.6-9.1,13.3-6.5c4.8,2.2,6.9,6.7,3.7,13.6c-0.1,0.3-0.4,0.8-0.6,1.1l-14.5-6.6
            c-3,6.9-1.8,10.3,3.4,12.6c2.3,1,4.4,1.3,6.6,1.2l-0.6,2.1c-2.3,0.2-4.7-0.1-7.2-1.3C882.3,2055.8,881.2,2050.9,885,2042.6z
            M900.2,2048.4c2.4-5.5,0.8-8.7-2.8-10.3c-4.2-1.9-7.3-0.3-9.7,4.6L900.2,2048.4z"
                  />
                  <path
                    class="st6"
                    d="M928.6,2049.6l-17.8,18.2l-2.7-1.1l0.3-25.4l2.5,1.1l-0.6,19.6l-0.1,3.2h0.1l2.2-2.4l13.5-14.3
            L928.6,2049.6z"
                  />
                  <path
                    class="st6"
                    d="M927,2060.8c2.6-6.9,7-9.5,12.8-7.3c5,1.9,7.3,6.2,4.6,13.4c-0.1,0.3-0.4,0.8-0.5,1.1l-14.8-5.6
            c-2.6,7.1-1.1,10.4,4.1,12.4c2.3,0.9,4.4,1,6.7,0.7l-0.5,2.1c-2.3,0.4-4.7,0.2-7.3-0.8C925.2,2074,923.8,2069.3,927,2060.8z
            M942.5,2065.5c2-5.6,0.2-8.7-3.4-10.1c-4.3-1.6-7.3,0.2-9.4,5.2L942.5,2065.5z"
                  />
                  <path
                    class="st6"
                    d="M946.2,2081.3l10.6-30l2.3,0.6l-10.7,30.2L946.2,2081.3z"
                  />
                  <path
                    class="st6"
                    d="M964.2,2088c-6.5-2.1-8.4-6.4-5.7-14.8c2.7-8.3,6.8-10.7,13.3-8.5c6.6,2.1,8.3,6.4,5.6,14.7
                                C974.6,2087.8,970.7,2090.2,964.2,2088z M964.8,2086c5,1.6,7.8-0.4,10.1-7.4s1.2-10.2-3.8-11.9c-5.1-1.7-7.9,0.3-10.2,7.3
                                C958.6,2081.1,959.8,2084.3,964.8,2086z"
                  />
                  <path
                    class="st6"
                    d="M988.3,2095.4c-2.6-0.8-4.8-2.3-5.8-4.8h-0.1l-0.9,3.1l-1.4,4.8l-2.4-0.7l8.2-28.1l1.8,0.3l-0.3,2.8h0.1
                                c2.1-1.4,4.9-1.8,7.9-0.9c5.5,1.6,7.9,5.3,5.3,14.2C998.1,2094.8,993.8,2097,988.3,2095.4z M994.2,2074
                                c-2.8-0.8-5.4-0.4-7.4,1.3l-3.7,12.8c0.5,2,2.1,4.2,5.3,5.1c4.1,1.2,7.6,0,9.8-7.7C1000.4,2078,998.5,2075.3,994.2,2074z"
                  />
                  <path
                    class="st6"
                    d="M1029.1,2105.6l3.8-15.6c0.9-3.7-0.8-6-4.2-6.8c-2.7-0.7-5.6,0.4-6.4,3.7l-4,16.1l-2.3-0.6l4-16.1
                                c0.9-3.6-1.2-5.5-4-6.2c-2.2-0.5-4.8,0.1-6.3,1.7l-4.4,17.9l-2.3-0.6l5.6-22.9l1.7,0.3l-0.2,2.9h0.1c1.7-1.5,4.3-2.1,6.8-1.5
            c3.3,0.8,5.1,2.6,5.4,5.4h0.1c1.7-2.3,4.4-3,7.4-2.3c4.4,1.1,6.7,4.2,5.5,9.5l-3.8,15.6L1029.1,2105.6z"
                  />
                  <path
                    class="st6"
                    d="M1039.3,2094.9c1.5-7.3,5.3-10.6,11.5-9.3c5.2,1,8.2,5,6.7,12.4c-0.1,0.4-0.2,0.9-0.3,1.2l-15.6-3.1
                                c-1.4,7.4,0.6,10.4,6.1,11.5c2.4,0.5,4.5,0.3,6.7-0.4l-0.1,2.1c-2.2,0.7-4.6,1-7.3,0.4
                                C1039.7,2108.3,1037.5,2103.8,1039.3,2094.9z M1055.4,2097.1c1.1-5.9-1.2-8.6-5.1-9.4c-4.6-0.9-7.1,1.4-8.4,6.7L1055.4,2097.1z"
                  />
                  <path
                    class="st6"
                    d="M1075.6,2114.5l2.7-15.7c0.7-4-1.2-6.1-5.2-6.7c-2.3-0.4-5.2,0.2-6.9,2.1l-3.1,18.2l-2.4-0.4l4-23.2
            l1.7,0.1v2.9h0.1c1.9-1.7,4.8-2.3,7.6-1.9c4.9,0.8,7.5,3.9,6.6,9.3l-2.7,15.7L1075.6,2114.5z"
                  />
                  <path
                    class="st6"
                    d="M1091.7,2095l-2.3,15.8c-0.4,2.8,0.9,4.1,3.2,4.5c0.7,0.1,1.5,0.1,2,0l-0.1,1.9c-0.7,0.2-1.8,0.2-2.9,0.1
            c-3.6-0.5-5.3-2.7-4.7-6.7l2.3-15.9l-3.6-0.5l0.3-1.9l3.7,0.5l1.4-6.1h1.9l-0.9,6.4l6.1,0.9l-0.3,1.9L1091.7,2095z"
                  />
                  <path
                    class="st6"
                    d="M1119.5,2120.8c-3.4-0.3-6.1-1.5-8.2-3.2l0.7-2.4c2.2,1.7,4.6,2.8,7.8,3.1c4.7,0.5,7.4-1.6,7.8-5.5
                                c0.8-8-14.9-6-13.9-15.9c0.4-4.5,3.7-7.6,10.1-7c2.9,0.3,5.5,1.1,7.5,2.4l-0.7,2.3c-2.2-1.2-4.3-2-6.9-2.2
                                c-4.9-0.5-7.1,1.8-7.4,4.6c-0.8,7.6,15,5.6,13.9,15.9C1129.6,2118.4,1125.9,2121.4,1119.5,2120.8z"
                  />
                  <path
                    class="st6"
                    d="M1152.3,2115.4c-0.3,4.9-3.4,7.7-9.3,7.3s-8.5-3.6-8.2-8.5l1.1-16.1l2.4,0.2l-1.1,16
            c-0.2,3.7,1.8,5.8,5.9,6.1c4.2,0.3,6.5-1.5,6.7-5.2l1.1-16l2.4,0.2L1152.3,2115.4z"
                  />
                  <path
                    class="st6"
                    d="M1168.2,2124c-2.7-0.1-5.2-1-6.8-3.3h-0.1l-0.1,3.2l-0.2,5l-2.5-0.1l1-29.2l1.8-0.1l0.4,2.7h0.1
                                c1.7-1.9,4.3-2.9,7.4-2.8c5.8,0.2,8.9,3.2,8.6,12.5C1177.6,2120.9,1173.9,2124.2,1168.2,2124z M1168.7,2101.8
                                c-3-0.1-5.3,0.9-6.8,3l-0.5,13.3c1,1.8,3.1,3.5,6.4,3.7c4.2,0.1,7.3-1.8,7.6-9.9C1175.6,2104.2,1173.2,2102,1168.7,2101.8z"
                  />
                  <path
                    class="st6"
                    d="M1192.8,2124.4c-2.7,0-5.2-0.8-6.9-3.1h-0.1v3.2v5h-2.5v-29.2l1.8-0.2l0.5,2.7h0.1c1.6-1.9,4.2-3.1,7.3-3.1
                                c5.8,0,9,2.9,9,12.2C1202.1,2121,1198.6,2124.4,1192.8,2124.4z M1192.6,2102.2c-3,0-5.3,1.1-6.7,3.3v13.3c1,1.8,3.2,3.4,6.5,3.4
                                c4.2,0,7.3-2.1,7.3-10.2C1199.6,2104.3,1197.1,2102.2,1192.6,2102.2z"
                  />
                  <path
                    class="st6"
                    d="M1216.4,2124c-6.9,0.2-10.1-3.1-10.3-12c-0.3-8.8,2.7-12.3,9.6-12.6c6.9-0.2,9.9,3.2,10.2,11.9
                                C1226.2,2120.3,1223.4,2123.8,1216.4,2124z M1216.4,2121.9c5.3-0.2,7.2-3,7-10.4c-0.2-7.3-2.4-10-7.6-9.8
            c-5.3,0.2-7.3,3-7.1,10.3C1208.9,2119.4,1211.1,2122.1,1216.4,2121.9z"
                  />
                  <path
                    class="st6"
                    d="M1232.1,2122.9l-1.4-23.4l1.9-0.3l0.2,2.6l0.3,3.2h0.1c1.1-3.8,3.7-6.4,7.3-6.6c0.8,0,1.6,0,2.3,0.1v2.5
            c-0.7-0.2-1.4-0.2-2.2-0.2c-4,0.2-7.1,4.1-6.8,9.8l0.7,12L1232.1,2122.9z"
                  />
                  <path
                    class="st6"
                    d="M1251.5,2099.9l1.3,15.9c0.2,2.8,1.8,3.8,4.2,3.6c0.7-0.1,1.5-0.2,2-0.5l0.4,1.9c-0.6,0.3-1.8,0.6-2.8,0.7
            c-3.7,0.3-5.8-1.5-6.1-5.5l-1.3-16l-3.7,0.3l-0.2-2l3.7-0.3v-6.2l1.9-0.4l0.5,6.4l6.2-0.5l0.2,2L1251.5,2099.9z"
                  />
                </g>
              </g>
            </g>

            <g
              class="stage-group"
              :class="{ active: activeStage === 'target-4-0' }"
              @click="handleClick('target-4-0')"
              @mouseover="useTarget('')"
            >
              <path
                class="st1"
                d="M1293.7,1495.1L1293.7,1495.1c-19.7,4.5-40.2,6.9-61.2,6.9c-64.8,0-124.3-22.4-171.3-59.9l-261.8,328.4
                        c123,98.1,275.7,151.5,433,151.5c52,0,103.9-5.8,154.6-17.4c0,0,0,0,0.1,0c0,0,0,0,0.1,0L1293.7,1495.1z"
              />
              <path class="cls-10 st23" d="M1078.1,1612.8c-3.9,0-7-1-9.6-2.6l.5-2.8c2.6,1.7,5.5,2.6,9,2.6,5.4,0,8.1-2.6,8.1-7,0-9-17.3-5.1-17.3-16.2s3.2-9,10.5-9,6.3.7,8.6,1.8l-.5,2.7c-2.6-1.1-5-1.7-8-1.7-5.6,0-7.8,2.8-7.8,6,0,8.6,17.4,4.5,17.4,16.2s-3.8,9.9-11,9.9Z" />
              <path class="cls-10 st23" d="M1099.7,1587.9v18c0,3.2,1.7,4.5,4.3,4.5s1.7-.1,2.2-.4l.2,2.2c-.7.3-2,.5-3.2.5-4.1,0-6.3-2.2-6.3-6.7v-18h-4.1v-2.2h4.2l.5-7,2.2-.3v7.2h7v2.2h-7Z" />
              <path class="cls-10 st23" d="M1127.6,1608.5h-.1c-1.4,2.5-4.6,4.1-8.3,4.1s-8.1-2.4-8.1-8.3,3.1-7.9,9.6-7.9,4.8.5,6.6,1.2v-3.3c0-5-2.1-6.7-6.8-6.7s-5.2.5-7.5,1.7l-.3-2.4c2.5-1.2,5.2-1.8,8.3-1.8,6.5,0,9.1,2.7,9.1,9.5v17.5h-2.1l-.3-3.7ZM1127.2,1599.7c-.7-.5-3-1.3-6.2-1.3-5.1,0-7.4,2.1-7.4,5.8s2.7,6,6.3,6,7.3-2.7,7.3-5.3v-5.3Z" />
              <path class="cls-10 st23" d="M1142.5,1600.1c-1.8.4-2.7,1.3-2.7,2.4s.8,1.9,3,2.1l6.1.6c6.5.7,8.5,2.7,8.5,6.2s-2.7,7.6-10.8,7.6-9.9-2.8-9.9-7,1-4,2.9-5.4c-1.3-.6-2.4-1.8-2.4-3.6s1.2-3.1,3.4-3.8c-1.9-1-3.2-3-3.2-6.3,0-5,2.5-7.8,9-7.8s4.7.5,6.5,1.8l4.3-1.9.6,1.4-3.4,2.3c.6,1.1.9,2.4.9,4.1,0,5-2.2,7.7-8.9,7.7s-2.6-.1-3.8-.5ZM1142,1607.2c-1.9,1.2-2.9,3-2.9,4.6,0,3.2,2.2,5,7.4,5s8.3-2,8.3-5-1.2-3.6-6.3-4l-6.5-.7ZM1140.1,1593c0,4.5,2.7,5.4,6.3,5.4s6.3-1.9,6.3-5.4-1.9-5.5-6.3-5.5-6.3,1.8-6.3,5.5Z" />
              <path class="cls-10 st23" d="M1161.5,1598c0-8.3,3.6-12.8,10.6-12.8s10.2,3.6,10.2,12.2,0,1,0,1.3h-17.9c.1,8.5,3,11.4,9.3,11.4s5.1-.7,7.3-1.9l.4,2.4c-2.3,1.3-4.9,2.1-8,2.1-8.5,0-11.8-4.5-11.8-14.7ZM1179.7,1596.9c0-6.7-3.2-9.2-7.6-9.2s-7.6,3.1-7.8,9.2h15.5Z" />
              <path class="cls-10 st23" d="M1221.3,1604.3v7.9h-2.7v-7.9h-16l-.3-1.9,15.3-23.9h3.6v23.3h4.7v2.6h-4.7ZM1218.9,1584.5v-3.6c.1,0,0,0,0,0l-1.9,3.2-9.3,14.7-2,2.9h0c0,.1,13,.1,13,.1v-17.2Z" />
              <path class="cls-10 st23" d="M1078.8,1633.5l6.7,17.2,1.3,3.4h.1l1.2-3.4,6.8-17.3h10.3v33.7h-8.6v-17.9c0,0,0,0,0,0l-1.3,3.5-5.5,14.4h-6.2l-5.5-14.5-1.2-3.5h0s0,3.7,0,3.7v14.3h-8.3v-33.7h10.3Z" />
              <path class="cls-10 st23" d="M1115,1637.6c-3.2,0-4.7-.9-4.7-3.4s1.5-3.4,4.7-3.4,4.7,1.2,4.7,3.4-1.4,3.4-4.7,3.4ZM1110.5,1667.2v-27.3l8.9-.4v27.7h-8.9Z" />
              <path class="cls-10 st23" d="M1134.2,1645.9v12.6c0,1.8.9,2.5,2.8,2.5s1.6-.1,2.1-.3l.5,6c-1,.5-3.1.9-5.2.9-6,0-8.9-3.5-8.9-8.9v-12.9h-2.6v-6.3h3.1l1.4-5.8,6.8-.6v6.4h5.7v6.3h-5.7Z" />
              <path class="cls-10 st23" d="M1147.9,1637.6c-3.2,0-4.7-.9-4.7-3.4s1.5-3.4,4.7-3.4,4.7,1.2,4.7,3.4-1.4,3.4-4.7,3.4ZM1143.5,1667.2v-27.3l8.9-.4v27.7h-8.9Z" />
              <path class="cls-10 st23" d="M1165.2,1656.2c-.3.2-.5.6-.5.9,0,.6.5.9,1.4,1l6.5.3c6.3.3,9,2.8,9,7.2s-4,9.1-13.2,9.1-11.6-3.1-11.6-7.2.6-3,1.9-4.1c-1.2-.9-2-2.2-2-4.1s.9-3.7,3.4-5c-1.8-1.3-3-3.4-3-6.4,0-4.9,3-8.7,11-8.7s4.9.4,6.9,1.4l4.7-1.4,1.8,4-2.7,2.2c.2.7.3,1.6.3,2.5,0,5.4-3.2,8.5-10.9,8.5s-2,0-3-.2ZM1164.8,1665c-.4.5-.6,1-.6,1.5,0,1.4,1.2,2.1,4.1,2.1,4.6,0,5.7-.6,5.7-1.7s-.5-1.3-2.8-1.4l-6.3-.4ZM1165.7,1648.1c0,2.1.9,2.7,2.4,2.7s2.7-.6,2.7-2.7-.7-2.7-2.6-2.7-2.6.7-2.6,2.7Z" />
              <path class="cls-10 st23" d="M1198.7,1664.9c-1.8,1.7-4.2,2.7-7.2,2.7-5.4,0-8.6-3.1-8.6-9.2s3.6-8.7,9.8-8.7,3.3.2,4.6.6v-1c0-2-1.2-3.2-4.5-3.2s-4.9.5-7.5,1.4l-.7-6.6c2.8-1.2,6.1-1.8,9.8-1.8,8.7,0,11.5,3.7,11.5,11.3v16.6h-6.7l-.6-2.2ZM1197.4,1655.8c-.6-.2-1.6-.4-3-.4-2.3,0-3.4.9-3.4,2.9s1.1,2.8,2.9,2.8,3.5-1,3.5-2.5v-2.8Z" />
              <path class="cls-10 st23" d="M1219.9,1645.9v12.6c0,1.8.9,2.5,2.8,2.5s1.6-.1,2.1-.3l.5,6c-1,.5-3.1.9-5.2.9-6,0-8.9-3.5-8.9-8.9v-12.9h-2.6v-6.3h3.1l1.4-5.8,6.8-.6v6.4h5.7v6.3h-5.7Z" />
              <path class="cls-10 st23" d="M1233.6,1637.6c-3.2,0-4.7-.9-4.7-3.4s1.5-3.4,4.7-3.4,4.7,1.2,4.7,3.4-1.4,3.4-4.7,3.4ZM1229.2,1667.2v-27.3l8.9-.4v27.7h-8.9Z" />
              <path class="cls-10 st23" d="M1255.1,1667.8c-9,0-13-4.2-13-14.3s4-14.4,13-14.4,13,4.3,13,14.4-3.9,14.3-13,14.3ZM1255.1,1661.1c3,0,4-1.4,4-7.5s-1-7.5-4-7.5-4,1.3-4,7.5,1.1,7.5,4,7.5Z" />
              <path class="cls-10 st23" d="M1287.1,1667.2v-17.2c0-2.5-1.1-3.5-3.3-3.5s-2.8.6-3.6,1.6v19.1h-8.6v-27.4l6.2-.4,1.2,2.2c2-1.6,4.6-2.5,7.3-2.5,5.8,0,9.5,3.9,9.5,10.8v17.2h-8.7Z" />
              <path class="cls-10 st23" d="M1087.7,1716.4h-10.4l-1.5,5.8h-9.9l11.2-33.7h11.2l11.4,33.7h-10.5l-1.5-5.8ZM1081.7,1699.4l-2.4,9.5h6.6l-2.4-9.4-.8-3.6h-.1l-.9,3.6Z" />
              <path class="cls-10 st23" d="M1119.1,1702.4c-1.3-.4-2.8-.7-4.2-.7-4.6,0-6,1.8-6,6.9s2.2,7,5.8,7,3.2-.3,4.5-.8l.6,6.5c-1.6.9-4,1.4-7,1.4-8.4,0-12.9-4.8-12.9-14.1s3.9-14.4,13.1-14.4,4.9.5,6.5,1.1l-.5,7Z" />
              <path class="cls-10 st23" d="M1132.7,1700.9v12.6c0,1.8.9,2.5,2.8,2.5s1.6-.1,2.1-.3l.5,6c-1,.5-3.1.9-5.2.9-6,0-8.9-3.5-8.9-8.9v-12.9h-2.6v-6.3h3.1l1.4-5.8,6.8-.6v6.4h5.7v6.3h-5.7Z" />
              <path class="cls-10 st23" d="M1146.5,1692.6c-3.2,0-4.7-.9-4.7-3.4s1.5-3.4,4.7-3.4,4.7,1.2,4.7,3.4-1.4,3.4-4.7,3.4ZM1142,1722.2v-27.3l8.9-.4v27.7h-8.9Z" />
              <path class="cls-10 st23" d="M1183.2,1694.7l-9.6,27.5h-10l-9.5-27.5h9.4l4.2,16,.9,3.5h.1l.9-3.5,4.3-16h9.4Z" />
              <path class="cls-10 st23" d="M1190.8,1692.6c-3.2,0-4.7-.9-4.7-3.4s1.5-3.4,4.7-3.4,4.7,1.2,4.7,3.4-1.4,3.4-4.7,3.4ZM1186.3,1722.2v-27.3l8.9-.4v27.7h-8.9Z" />
              <path class="cls-10 st23" d="M1210,1700.9v12.6c0,1.8.9,2.5,2.8,2.5s1.6-.1,2.1-.3l.5,6c-1,.5-3.1.9-5.2.9-6,0-8.9-3.5-8.9-8.9v-12.9h-2.6v-6.3h3.1l1.4-5.8,6.8-.6v6.4h5.7v6.3h-5.7Z" />
              <path class="cls-10 st23" d="M1228.4,1721.6v-.3c0,0-11.6-26.6-11.6-26.6h9.8l4.9,14.7,1.1,3.5h.1l.8-3.6,3.5-14.6h9.2l-9.1,26.9c-2.1,5.9-5.6,8.4-10.9,8.4s-3.4-.3-5-.9l.6-5.8c1,.4,1.8.5,2.7.5,1.8,0,3.1-.4,3.9-2.2Z" />
              <path class="cls-10 st23" d="M1081.8,1777.2h-13.4v-33.7h13.2c11,0,15.6,5.2,15.6,16.7s-5.3,17.1-15.4,17.1ZM1081.7,1751.5h-3.8v17.6h3.8c4.3,0,5.7-1.3,5.7-9.1s-1.3-8.5-5.7-8.5Z" />
              <path class="cls-10 st23" d="M1099.9,1763.3c0-8.8,4-14,12.6-14s11.7,4.1,11.7,12.8,0,2.7-.2,3.9h-15.1c.4,3.3,2.3,4.6,6.4,4.6s4.9-.4,7.2-1.1l.9,6.7c-2.3,1-5.7,1.7-9.3,1.7-9.8,0-14.3-5.1-14.3-14.4ZM1116.3,1760.5c0-3.2-1.3-4.4-3.7-4.4s-3.6,1-3.8,4.4h7.6Z" />
              <path class="cls-10 st23" d="M1135.5,1777.7c-3.4,0-6.3-.5-8.7-1.7l.9-6.8c2.3,1.2,5.6,1.8,7.8,1.8s3-.8,3-1.9c0-3.5-12-1.1-12-10.9s3.5-8.9,10.9-8.9,5.7.6,8,1.5l-.6,6.8c-2.3-.9-5.5-1.3-7.5-1.3s-2.5.6-2.5,1.6c0,3.3,11.7,1.1,11.7,10.5s-3.1,9.5-11,9.5Z" />
              <path class="cls-10 st23" d="M1154.9,1747.6c-3.2,0-4.7-.9-4.7-3.4s1.5-3.4,4.7-3.4,4.7,1.2,4.7,3.4-1.4,3.4-4.7,3.4ZM1150.5,1777.2v-27.3l8.9-.4v27.7h-8.9Z" />
              <path class="cls-10 st23" d="M1172.2,1766.2c-.3.2-.5.6-.5.9,0,.6.5.9,1.4,1l6.5.3c6.3.3,9,2.8,9,7.2s-4,9.1-13.2,9.1-11.6-3.1-11.6-7.2.6-3,1.9-4.1c-1.2-.9-2-2.2-2-4.1s.9-3.7,3.4-5c-1.8-1.3-3-3.4-3-6.4,0-4.9,3-8.7,11-8.7s4.9.4,6.9,1.4l4.7-1.4,1.8,4-2.7,2.2c.2.7.3,1.6.3,2.5,0,5.4-3.2,8.5-10.9,8.5s-2,0-3-.2ZM1171.7,1775c-.4.5-.6,1-.6,1.5,0,1.4,1.2,2.1,4.1,2.1,4.6,0,5.7-.6,5.7-1.7s-.5-1.3-2.8-1.4l-6.3-.4ZM1172.6,1758.1c0,2.1.9,2.7,2.4,2.7s2.7-.6,2.7-2.7-.7-2.7-2.6-2.7-2.6.7-2.6,2.7Z" />
              <path class="cls-10 st23" d="M1206.8,1777.2v-17.2c0-2.5-1.1-3.5-3.3-3.5s-2.8.6-3.6,1.6v19.1h-8.6v-27.4l6.2-.4,1.2,2.2c2-1.6,4.6-2.5,7.3-2.5,5.8,0,9.5,3.9,9.5,10.8v17.2h-8.7Z" />
            </g>

            <g class="target-group">
              <g
                id="target-4-2"
                class="target-item"
                :class="{ active: activeStage === 'target-4-2' }"
                @mouseover="useTarget('target-4-2')"
              >
                <path
                  class="st24"
                  d="M1090.7,1847.2c-31.3-7.2-61.6-16.6-90.7-27.9l-41.7,106.2c34.8,13.7,70.6,24.7,107.1,33.1
            c36.5,8.3,73.5,13.9,110.8,16.7l8.6-113.7C1153.6,1859.1,1122.2,1854.4,1090.7,1847.2z"
                />
                <g>
                  <path
                    class="st10"
                    d="M1069.5,1907v2.9h-4.2v-2.9h-7.1l-0.4-2.7l6.3-10.9h5.4v10h1.5l-0.1,3.6H1069.5z M1065.6,1899.3l0.1-1.8
            l0,0l-0.8,1.6l-2.3,4.3h3L1065.6,1899.3z"
                  />
                  <path
                    class="st10"
                    d="M1074.5,1910.2c-1.7,0-2.4-0.6-2.4-2.2c0-1.4,0.7-2.2,2.4-2.2s2.4,0.8,2.4,2.2
            C1076.8,1909.4,1076.1,1910.2,1074.5,1910.2z"
                  />
                  <path
                    class="st10"
                    d="M1084.8,1906L1084.8,1906l5.6,0.1l-0.1,3.8h-11.2v-2.8c3.8-3.9,6.7-6.4,6.7-8.5c0-1-0.6-1.5-2.1-1.5
            c-1.3,0-2.6,0.6-3.8,1.3l-1.1-3.5c1.5-1,3.4-1.7,5.7-1.7c4,0,5.9,2.1,5.9,5C1090.4,1901,1088.1,1903.2,1084.8,1906z"
                  />
                </g>
              </g>
              <g
                id="target-4-1"
                class="target-item"
                :class="{ active: activeStage === 'target-4-1' }"
                @mouseover="useTarget('target-4-1')"
              >
                <path
                  class="st2"
                  d="M1398.9,1958.7L1398.9,1958.7C1399,1958.7,1399,1958.7,1398.9,1958.7L1398.9,1958.7z"
                />
                <path
                  class="st1"
                  d="M1399.2,1958.6L1399.2,1958.6c-0.1,0-0.1,0-0.2,0C1399.1,1958.6,1399.2,1958.6,1399.2,1958.6z"
                />
                <path
                  class="st25"
                  d="M1373.9,1847.5c-0.1,0-0.1,0-0.2,0.1c-60.8,13.9-124.4,18.9-188.8,14l-8.6,113.7c37.3,2.8,74.8,2.8,112.1,0
            c37.2-2.8,74.2-8.3,110.6-16.6h0.1c0.1,0,0.1,0,0.2,0L1373.9,1847.5z"
                />
                <g>
                  <path
                    class="st10"
                    d="M1280.3,1923.3v2.9h-4.2v-2.9h-7.1l-0.4-2.7l6.3-10.9h5.4v10h1.5l-0.1,3.6H1280.3z M1276.5,1915.6l0.1-1.8
            l0,0l-0.8,1.6l-2.3,4.3h3L1276.5,1915.6z"
                  />
                  <path
                    class="st10"
                    d="M1285.3,1926.4c-1.7,0-2.4-0.6-2.4-2.2c0-1.4,0.7-2.2,2.4-2.2s2.4,0.8,2.4,2.2
            C1287.7,1925.6,1287,1926.4,1285.3,1926.4z"
                  />
                  <path
                    class="st10"
                    d="M1291.8,1923.1h2.1v-8.3l-2.7,1.5l-0.4-3.6l5-3.1h2.7v13.5h2.1v3.1h-8.9v-3.1H1291.8z"
                  />
                </g>
              </g>
              <g
                id="target-4-3"
                class="target-item"
                :class="{ active: activeStage === 'target-4-3' }"
                @mouseover="useTarget('target-4-3')"
              >
                <path
                  class="st26"
                  d="M836,1724.6C836,1724.5,835.9,1724.5,836,1724.6l-71.2,89c0,0,0,0,0.1,0l0,0c29.2,23.3,60.1,44.4,92.5,63.1
            s66.1,35,100.9,48.7l41.7-106.2C939.8,1795.7,884.8,1763.5,836,1724.6z"
                />
                <g>
                  <path
                    class="st10"
                    d="M878.4,1830.8v2.9h-4.2v-2.9h-7.1l-0.4-2.7l6.3-10.9h5.4v10h1.5l-0.1,3.6H878.4z M874.5,1823.1l0.1-1.8
            l0,0l-0.8,1.6l-2.3,4.3h3V1823.1z"
                  />
                  <path
                    class="st10"
                    d="M883.4,1834c-1.7,0-2.4-0.6-2.4-2.2c0-1.4,0.7-2.2,2.4-2.2s2.4,0.8,2.4,2.2
            C885.7,1833.2,885,1834,883.4,1834z"
                  />
                  <path
                    class="st10"
                    d="M892.8,1833.9c-2,0-3.8-0.5-5-1.2l0.8-3.4c1.3,0.6,2.6,1,4.1,1c1.6,0,2.4-0.6,2.4-1.7c0-1-0.6-1.8-3.9-1.8
            v-3.1c3.1,0,3.7-0.6,3.7-1.7c0-0.8-0.6-1.3-2.2-1.3c-1.6,0-2.9,0.5-4.1,1.3l-1-3.4c1.3-1,3.4-1.7,5.4-1.7c3.8,0,6.1,1.8,6.1,4.8
            c0,1.5-0.7,2.7-2.1,3.4l0,0c1.5,0.6,2.4,1.8,2.4,3.6C899.5,1831.9,897.4,1833.9,892.8,1833.9z"
                  />
                </g>
              </g>
            </g>
          </g>

          <!-- Stage 5 -->
          <g
            class="stage-main-group"
            :class="{ active: activeMainGroup === 5 }"
            @mouseover="setIsActive(5)"
          >
            <g
              class="stage-group"
              :class="{ active: activeStage === 'target-5-0' }"
              @click="handleClick('target-5-0')"
              @mouseover="useTarget('')"
            >
              <path
                class="st12"
                d="M1061.2,1442c-63-50.3-103.4-127.8-103.5-214.7l-419.9,0.1c0,104.3,23.6,207.3,68.8,301.2
            c45.3,94,111.2,176.6,192.7,241.7l0,0l0,0L1061.2,1442C1061.2,1442.1,1061.2,1442.1,1061.2,1442z"
              />
              <path class="cls-17 st13" d="M737.1,1417.2c-3.9,0-7.1-1-9.7-2.6l.5-2.8c2.6,1.7,5.5,2.6,9.1,2.6,5.4,0,8.2-2.6,8.2-7.1,0-9.1-17.5-5.1-17.5-16.4s3.3-9.1,10.6-9.1,6.3.7,8.7,1.8l-.5,2.7c-2.6-1.1-5.1-1.7-8.1-1.7-5.6,0-7.9,2.9-7.9,6.1,0,8.7,17.6,4.6,17.6,16.4.1,6.3-3.8,10.1-11,10.1Z" />
              <path class="cls-17 st13" d="M758.8,1392v18.1c0,3.2,1.7,4.5,4.4,4.5s1.7,0,2.3-.4l.2,2.2c-.7.3-2,.5-3.2.5-4.2,0-6.4-2.2-6.4-6.8v-18.1h-4.2v-2.2h4.2l.5-7.1,2.2-.3v7.3h7.1v2.2h-7.1Z" />
              <path class="cls-17 st13" d="M787.1,1412.8c-1.6,2.5-4.7,4.2-8.5,4.2s-8.2-2.4-8.2-8.4,3.1-8,9.7-8,4.9.5,6.7,1.2v-3.3c0-5.1-2.1-6.7-6.9-6.7s-5.3.5-7.6,1.7l-.3-2.4c2.5-1.2,5.3-1.8,8.4-1.8,6.6,0,9.2,2.7,9.2,9.6v17.7h-2.1l-.4-3.8ZM786.7,1403.9c-.7-.5-3-1.3-6.3-1.3-5.2,0-7.5,2.1-7.5,5.9s2.7,6.1,6.3,6.1,7.4-2.7,7.4-5.3v-5.4Z" />
              <path class="cls-17 st13" d="M802.2,1404.4c-1.8.4-2.8,1.3-2.8,2.5s.8,1.8,2.6,2.1l6.6.7c6.6.7,8.6,2.7,8.6,6.2s-2.7,7.7-10.9,7.7-10-2.8-10-7.1,1-4,3-5.5c-1.4-.6-2.5-1.8-2.5-3.6s1.3-3.1,3.4-3.8h0c-1.9-1.1-3.2-3.1-3.2-6.4,0-5,2.5-7.9,9.1-7.9s4.8.5,6.5,1.9l4.3-2,.6,1.4-3.5,2.3c.6,1.1,1,2.5,1,4.2,0,5-2.3,7.8-9,7.8s-2.6,0-3.8-.5ZM801.7,1411.5c-1.9,1.2-2.9,3-2.9,4.7,0,3.2,2.2,5.1,7.5,5.1s8.4-2,8.4-5-1.2-3.6-6.4-4l-6.6-.8ZM799.7,1397.2c0,4.5,2.7,5.5,6.3,5.5s6.4-1.9,6.4-5.5-2-5.6-6.4-5.6-6.3,1.9-6.3,5.6Z" />
              <path class="cls-17 st13" d="M821.3,1402.3c0-8.4,3.6-13,10.7-13s10.3,3.7,10.3,12.4,0,1-.1,1.4h-18.1c0,8.6,3,11.5,9.4,11.5s5.1-.7,7.4-1.9l.4,2.4c-2.3,1.3-4.9,2.1-8.1,2.1-8.5,0-11.9-4.6-11.9-14.9ZM839.7,1401.1c0-6.8-3.3-9.3-7.7-9.3s-7.6,3.1-7.9,9.3h15.6Z" />
              <path class="cls-17 st13" d="M873.5,1417c-4,0-7.1-1-9.6-2.8l.6-2.6c2.6,1.7,5.2,2.8,8.8,2.8,6,0,8.9-3,8.9-9.1s-2.7-7.5-8.6-7.5-6.4.9-8.5,2l-1.2-1.1,1.4-16.2h18.1v2.7c0,0-15.9,0-15.9,0l-1,11.6c2.3-1,5.2-1.5,7.8-1.5,7.1,0,10.7,3.2,10.7,9.8,0,7.8-3.8,11.9-11.5,11.9Z" />
              <path class="cls-17 st13" d="M757.7,1437.1l-11.3,34.1h-10.6l-11.2-34.1h10.5l5.4,21,.9,3.5h0l.8-3.5,5.5-21h9.9Z" />
              <path class="cls-17 st13" d="M773.1,1468.9c-1.8,1.7-4.3,2.7-7.2,2.7-5.4,0-8.7-3.1-8.7-9.3s3.7-8.8,9.9-8.8,3.4.2,4.7.6v-1c0-2-1.2-3.2-4.6-3.2s-5,.5-7.6,1.4l-.7-6.6c2.9-1.2,6.2-1.8,9.9-1.8,8.8,0,11.6,3.8,11.6,11.5v16.8h-6.7l-.6-2.3ZM771.7,1459.7c-.6-.2-1.6-.4-3-.4-2.3,0-3.4,1-3.4,3s1.1,2.8,2.9,2.8,3.5-1,3.5-2.5v-2.9Z" />
              <path class="cls-17 st13" d="M785,1471.2v-35.2l8.9-.4v35.6h-8.9Z" />
              <path class="cls-17 st13" d="M803.9,1441.3c-3.3,0-4.8-.9-4.8-3.4s1.5-3.4,4.8-3.4,4.7,1.2,4.7,3.4-1.4,3.4-4.7,3.4ZM799.3,1471.2v-27.6l9-.4v28h-9Z" />
              <path class="cls-17 st13" d="M831,1471.2l-.8-2.2c-1.8,1.7-4,2.7-7.1,2.7-7,0-10.6-4.7-10.6-14.6s3.6-14.3,10.5-14.3,4.5.7,6.2,2.1h0c0,0,0-3.7,0-3.7v-5.2l8.7-.4v35.6h-6.9ZM829.1,1451.9c-.7-.9-2-1.5-3.5-1.5-3,0-4,1.6-4,7.1s.9,7.5,4.1,7.5,3-.9,3.5-1.8v-11.3Z" />
              <path class="cls-17 st13" d="M857.4,1468.9c-1.8,1.7-4.3,2.7-7.2,2.7-5.4,0-8.7-3.1-8.7-9.3s3.7-8.8,9.9-8.8,3.4.2,4.7.6v-1c0-2-1.2-3.2-4.6-3.2s-5,.5-7.6,1.4l-.7-6.6c2.9-1.2,6.2-1.8,9.9-1.8,8.8,0,11.6,3.8,11.6,11.5v16.8h-6.7l-.6-2.3ZM856.1,1459.7c-.6-.2-1.6-.4-3-.4-2.3,0-3.4,1-3.4,3s1.1,2.8,2.9,2.8,3.5-1,3.5-2.5v-2.9Z" />
              <path class="cls-17 st13" d="M878.9,1449.7v12.8c0,1.8.9,2.5,2.9,2.5s1.6,0,2.1-.3l.5,6.1c-1,.5-3.1.9-5.3.9-6.1,0-9-3.5-9-9v-13h-2.6v-6.3h3.1l1.4-5.9,6.9-.6v6.5h5.8v6.3h-5.8Z" />
              <path class="cls-17 st13" d="M892.8,1441.3c-3.3,0-4.8-.9-4.8-3.4s1.5-3.4,4.8-3.4,4.7,1.2,4.7,3.4-1.4,3.4-4.7,3.4ZM888.3,1471.2v-27.6l9-.4v28h-9Z" />
              <path class="cls-17 st13" d="M914.5,1471.8c-9.1,0-13.1-4.2-13.1-14.4s4.1-14.6,13.2-14.6,13.1,4.4,13.1,14.6-4,14.4-13.2,14.4ZM914.5,1465c3,0,4-1.5,4-7.6s-1-7.6-4-7.6-4.1,1.4-4.1,7.6,1.1,7.6,4.1,7.6Z" />
              <path class="cls-17 st13" d="M946.8,1471.2v-17.4c0-2.5-1.1-3.5-3.4-3.5s-2.9.6-3.6,1.6v19.3h-8.7v-27.7l6.3-.4,1.2,2.2c2-1.6,4.6-2.5,7.4-2.5,5.9,0,9.6,3.9,9.6,11v17.4h-8.8Z" />
            </g>
            <g class="target-group">
              <g
                id="target-5-2"
                class="target-item"
                :class="{ active: activeStage === 'target-5-2' }"
                @mouseover="useTarget('target-5-2')"
              >
                <path
                  class="st14"
                  d="M596.8,1227.5h-114c0,56.1,6.3,112,18.7,166.7c12.5,54.7,31,107.9,55.4,158.5l102.9-49.5
            C619,1418.6,596.7,1324.6,596.8,1227.5z"
                />
                <g>
                  <path
                    class="st10"
                    d="M545.1,1382.1c-1.9,0-3.8-0.4-5.3-1.2l0.8-3.4c1.4,0.6,2.4,1,4,1c1.9,0,2.4-0.7,2.4-2
            c0-1.2-0.8-1.7-2.4-1.7c-1,0-2.1,0.2-3,0.4l-1.5-1.7l0.7-8.3h9.7l0.2,3.8h-6.3l-0.2,2.3c0.6-0.1,1.2-0.1,1.9-0.1
            c3.4,0,5.4,1.6,5.4,4.8C551.5,1379.7,549.6,1382.1,545.1,1382.1z"
                  />
                  <path
                    class="st10"
                    d="M555.6,1382.1c-1.7,0-2.4-0.6-2.4-2.2c0-1.4,0.7-2.2,2.4-2.2s2.4,0.8,2.4,2.2
            C558,1381.3,557.3,1382.1,555.6,1382.1z"
                  />
                  <path
                    class="st10"
                    d="M565.9,1377.9L565.9,1377.9l5.6,0.1l-0.1,3.8h-11.2v-2.8c3.8-3.9,6.7-6.4,6.7-8.5c0-1-0.6-1.5-2.1-1.5
            c-1.3,0-2.6,0.6-3.8,1.3l-1.1-3.5c1.5-1,3.4-1.7,5.7-1.7c4,0,5.9,2.1,5.9,5C571.6,1372.9,569.3,1375.1,565.9,1377.9z"
                  />
                </g>
              </g>
              <g
                id="target-5-1"
                class="target-item"
                :class="{ active: activeStage === 'target-5-1' }"
                @mouseover="useTarget('target-5-1')"
              >
                <path
                  class="st15"
                  d="M659.8,1503.2l-102.9,49.5c24.3,50.6,54.3,98.3,89.3,142.2c35,43.9,74.7,83.7,118.6,118.7l71.1-89.1
            C761,1664.6,701,1588.7,659.8,1503.2z"
                />
                <g>
                  <path
                    class="st10"
                    d="M668.6,1656.6c-1.9,0-3.8-0.4-5.3-1.2l0.8-3.4c1.4,0.6,2.4,1,4,1c1.9,0,2.4-0.7,2.4-2
                                c0-1.2-0.8-1.7-2.4-1.7c-1,0-2.1,0.2-3,0.4l-1.5-1.7l0.7-8.3h9.7l0.2,3.8h-6.3l-0.2,2.3c0.6-0.1,1.2-0.1,1.9-0.1
            c3.4,0,5.4,1.6,5.4,4.8C675,1654.3,673.1,1656.6,668.6,1656.6z"
                  />
                  <path
                    class="st10"
                    d="M679.1,1656.6c-1.7,0-2.4-0.6-2.4-2.2c0-1.4,0.7-2.2,2.4-2.2s2.4,0.8,2.4,2.2
            C681.4,1655.8,680.7,1656.6,679.1,1656.6z"
                  />
                  <path
                    class="st10"
                    d="M685.5,1653.3h2.1v-8.3l-2.7,1.5l-0.4-3.6l5-3.1h2.7v13.5h2.1v3.1h-8.9v-3.1H685.5z"
                  />
                </g>
              </g>
            </g>
          </g>

          <!-- Stage 6 -->
          <g
            class="stage-main-group"
            :class="{ active: activeMainGroup === 6 }"
            @mouseover="setIsActive(6)"
          >
            <g
              class="stage-group"
              :class="{ active: activeStage === 'target-6-0' }"
              @click="handleClick('target-6-0')"
              @mouseover="useTarget('')"
            >
              <path
                class="st16"
                d="M1061.1,1012.5L799.2,684.3c-165.1,131.7-261.4,331.8-261.4,543c0,0.1,0,0.2,0,0.2l419.9-0.1v-0.1
            C957.8,1140.3,998.1,1062.9,1061.1,1012.5z"
              />
              <path
                class="st17"
                d="M1061.2,1012.5L799.3,684.2l0,0c0,0,0,0-0.1,0L1061.2,1012.5L1061.2,1012.5z"
              />
              <path class="cls-1 st13" d="M687.5,1017.6c-3.9,0-7.1-1-9.7-2.6l.5-2.8c2.6,1.7,5.5,2.6,9.1,2.6,5.4,0,8.2-2.6,8.2-7.1,0-9.1-17.5-5.1-17.5-16.4s3.3-9.1,10.6-9.1,6.3.7,8.7,1.8l-.5,2.7c-2.6-1.1-5.1-1.7-8.1-1.7-5.6,0-7.9,2.9-7.9,6.1,0,8.7,17.6,4.6,17.6,16.4,0,6.3-3.7,10.1-11,10.1Z" />
              <path class="cls-1 st13" d="M709.3,992.4v18.1c0,3.2,1.7,4.5,4.4,4.5s1.7-.1,2.3-.4l.2,2.2c-.7.3-2,.5-3.2.5-4.2,0-6.4-2.2-6.4-6.8v-18.2h-4.2v-2.2h4.2l.5-7.1,2.2-.3v7.3h7.1v2.2h-7.1v.2Z" />
              <path class="cls-1 st13" d="M737.5,1013.2c-1.6,2.5-4.7,4.2-8.5,4.2s-8.2-2.4-8.2-8.4,3.1-8,9.7-8,4.9.5,6.7,1.2v-3.3c0-5.1-2.1-6.7-6.9-6.7s-5.3.5-7.6,1.7l-.3-2.4c2.5-1.2,5.3-1.8,8.4-1.8,6.6,0,9.2,2.7,9.2,9.6v17.7h-2.1l-.4-3.8ZM737.2,1004.3c-.7-.5-3-1.3-6.3-1.3-5.2,0-7.5,2.1-7.5,5.9s2.7,6.1,6.3,6.1,7.4-2.7,7.4-5.3v-5.4Z" />
              <path class="cls-1 st13" d="M752.5,1004.8c-1.8.4-2.8,1.3-2.8,2.5s.8,1.8,2.6,2.1l6.6.7c6.6.7,8.6,2.7,8.6,6.2s-2.7,7.7-10.9,7.7-10-2.8-10-7.1,1-4,3-5.5c-1.4-.6-2.5-1.8-2.5-3.6s1.3-3.1,3.4-3.8h0c-1.9-1.1-3.2-3.1-3.2-6.4,0-5,2.5-7.9,9.1-7.9s4.8.5,6.5,1.9l4.3-2,.6,1.4-3.5,2.3c.6,1.1,1,2.5,1,4.2,0,5-2.3,7.8-9,7.8s-2.5-.2-3.8-.5ZM752,1011.9c-1.9,1.2-2.9,3-2.9,4.7,0,3.2,2.2,5.1,7.5,5.1s8.4-2,8.4-5-1.2-3.6-6.4-4l-6.6-.8ZM750,997.6c0,4.5,2.7,5.5,6.3,5.5s6.4-1.9,6.4-5.5-2-5.6-6.4-5.6-6.3,1.9-6.3,5.6Z" />
              <path class="cls-1 st13" d="M771.8,1002.6c0-8.4,3.6-13,10.7-13s10.3,3.7,10.3,12.4,0,1,0,1.4h-18.1c.1,8.6,3,11.5,9.4,11.5s5.1-.7,7.4-1.9l.4,2.4c-2.3,1.3-4.9,2.1-8.1,2.1-8.5,0-11.9-4.5-11.9-14.9ZM790.2,1001.5c-.1-6.8-3.3-9.3-7.7-9.3s-7.6,3.1-7.9,9.3h15.6Z" />
              <path class="cls-1 st13" d="M824.7,1017.3c-7.6,0-11.6-4.1-11.6-16.2s4.8-18.6,13.8-18.6,4.9.4,7.3,1.4l-.3,2.6c-2.2-.9-4.1-1.3-6.7-1.3-7.2,0-11,4.2-11.4,13.8h.1c2.5-2.2,6-3.5,9.5-3.5,7.3,0,10.6,3.2,10.6,9.9,0,7.8-3.9,11.9-11.3,11.9ZM824.9,997.9c-3,0-6.9,1.5-8.9,4,0,9.4,2.7,12.9,8.7,12.9s8.4-3.2,8.4-9.4-2.5-7.5-8.2-7.5Z" />
              <path class="cls-1 st13" d="M697.1,1065.7h-10.6l-1.5,5.9h-10.1l11.3-34.1h11.4l11.5,34.1h-10.6l-1.4-5.9ZM691,1048.5l-2.5,9.6h6.7l-2.4-9.5-.8-3.6h0l-.9,3.5Z" />
              <path class="cls-1 st13" d="M734.9,1061.4c0,7-4,10.6-12.3,10.6s-12.2-3.8-12.2-10.6v-17.7h8.7v17.6c0,2.2,1,3.3,3.5,3.3s3.5-1,3.5-3.3v-17.6h8.8v17.7Z" />
              <path class="cls-1 st13" d="M748.8,1050.1v12.8c0,1.8.9,2.5,2.9,2.5s1.6,0,2.1-.3l.5,6.1c-1,.5-3.1.9-5.3.9-6.1,0-9-3.5-9-9v-13h-2.6v-6.3h3.1l1.4-5.9,6.9-.6v6.5h5.8v6.3h-5.8Z" />
              <path class="cls-1 st13" d="M773.5,1071.6v-17.4c0-2.4-1-3.5-3.4-3.5s-2.9.7-3.6,1.6v19.3h-8.7v-35.2l8.7-.4v9.1c0,0,0,0,0,0,2-1.5,4.4-2,6.4-2,5.8,0,9.3,4.1,9.3,11v17.4h-8.7Z" />
              <path class="cls-1 st13" d="M798.6,1072.2c-9.1,0-13.1-4.2-13.1-14.4s4.1-14.6,13.2-14.6,13.1,4.4,13.1,14.6-4,14.4-13.2,14.4ZM798.6,1065.4c3,0,4-1.5,4-7.6s-1-7.6-4-7.6-4.1,1.4-4.1,7.6,1.1,7.6,4.1,7.6Z" />
              <path class="cls-1 st13" d="M815.3,1071.6v-27.6l7-.3.8,4h.1c1.7-3,4.3-4.4,7.1-4.4s2.4.2,3.1.5l-.5,8.3c-.9-.3-2-.5-3.2-.5-3.4,0-5.6,2.2-5.6,6.1v13.9h-8.8Z" />
              <path class="cls-1 st13" d="M841.6,1041.7c-3.3,0-4.8-.9-4.8-3.4s1.5-3.4,4.8-3.4,4.7,1.2,4.7,3.4-1.4,3.4-4.7,3.4ZM837.1,1071.6v-27.6l9-.4v28h-9Z" />
              <path class="cls-1 st13" d="M859.4,1072.1c-3.4,0-6.4-.5-8.8-1.7l.9-6.9c2.4,1.2,5.7,1.8,7.9,1.8s3-.8,3-1.9c0-3.5-12.1-1.1-12.1-11.1s3.5-9,11.1-9,5.7.6,8.1,1.5l-.6,6.8c-2.3-.9-5.6-1.3-7.6-1.3s-2.5.6-2.5,1.6c0,3.3,11.9,1.1,11.9,10.6-.2,5.7-3.3,9.6-11.3,9.6Z" />
              <path class="cls-1 st13" d="M888.8,1069.3c-1.8,1.7-4.3,2.7-7.2,2.7-5.4,0-8.7-3.1-8.7-9.3s3.7-8.8,9.9-8.8,3.4.2,4.7.6v-1c0-2-1.2-3.2-4.6-3.2s-5,.5-7.6,1.4l-.7-6.6c2.9-1.2,6.2-1.8,9.9-1.8,8.8,0,11.6,3.8,11.6,11.5v16.8h-6.7l-.6-2.3ZM887.5,1060.1c-.6-.2-1.6-.4-3-.4-2.3,0-3.4,1-3.4,3s1.1,2.8,2.9,2.8,3.5-1,3.5-2.5v-2.9Z" />
              <path class="cls-1 st13" d="M910.3,1050.1v12.8c0,1.8.9,2.5,2.9,2.5s1.6,0,2.1-.3l.5,6.1c-1,.5-3.1.9-5.3.9-6.1,0-9-3.5-9-9v-13h-2.6v-6.3h3.1l1.4-5.9,6.9-.6v6.5h5.8v6.3h-5.8Z" />
              <path class="cls-1 st13" d="M924.2,1041.7c-3.3,0-4.8-.9-4.8-3.4s1.5-3.4,4.8-3.4,4.7,1.2,4.7,3.4-1.4,3.4-4.7,3.4ZM919.7,1071.6v-27.6l9-.4v28h-9Z" />
              <path class="cls-1 st13" d="M946,1072.2c-9.1,0-13.1-4.2-13.1-14.4s4.1-14.6,13.2-14.6,13.1,4.4,13.1,14.6-4,14.4-13.2,14.4ZM946,1065.4c3,0,4-1.5,4-7.6s-1-7.6-4-7.6-4.1,1.4-4.1,7.6,1.1,7.6,4.1,7.6Z" />
              <path class="cls-1 st13" d="M978.2,1071.6v-17.4c0-2.5-1.1-3.5-3.4-3.5s-2.9.6-3.6,1.6v19.3h-8.7v-27.7l6.3-.4,1.2,2.2c2-1.6,4.6-2.5,7.4-2.5,5.9,0,9.6,3.9,9.6,11v17.4h-8.8Z" />
            </g>

            <g class="target-group">
              <g
                id="target-6-2"
                class="target-item"
                :class="{ active: activeStage === 'target-6-2' }"
                @mouseover="useTarget('target-6-2')"
              >
                <path
                  class="st18"
                  d="M836.3,730.8l-71.2-89.2c-43.8,34.9-83.5,74.6-118.5,118.4c-35,43.9-65,91.6-89.4,142.1l102.7,49.5
            C702,864.2,762.9,789.3,836.3,730.8z"
                />
                <g>
                  <path
                    class="st10"
                    d="M683,806.6c-4.2,0-6.5-2.3-6.5-8.2c0-5.6,2.6-8.8,7.6-8.8c1.5,0,2.8,0.2,4,0.6l-0.3,3.7
                                c-1-0.4-2-0.5-3.1-0.5c-2.3,0-3.3,0.9-3.7,2.7l0,0c0.8-0.4,1.7-0.7,2.8-0.7c3.4,0,5.3,1.7,5.3,5
            C689,804.7,686.7,806.6,683,806.6z M682.8,798.7c-0.5,0-1.5,0.3-1.8,0.8c0,2.7,0.6,3.4,1.9,3.4c1.1,0,1.7-0.7,1.7-2.5
            C684.6,799.3,684.1,798.7,682.8,798.7z"
                  />
                  <path
                    class="st10"
                    d="M692.8,806.6c-1.7,0-2.4-0.6-2.4-2.2c0-1.4,0.7-2.2,2.4-2.2s2.4,0.8,2.4,2.2S694.5,806.6,692.8,806.6z"
                  />
                  <path
                    class="st10"
                    d="M703.1,802.5L703.1,802.5l5.6,0.1l-0.1,3.8h-11.2v-2.8c3.8-3.9,6.7-6.4,6.7-8.5c0-1-0.6-1.5-2.1-1.5
            c-1.3,0-2.6,0.6-3.8,1.3l-1.1-3.5c1.5-1,3.4-1.7,5.7-1.7c4,0,5.9,2.1,5.9,5C708.8,797.4,706.5,799.6,703.1,802.5z"
                  />
                </g>
              </g>
              <g
                id="target-6-1"
                class="target-item"
                :class="{ active: activeStage === 'target-6-1' }"
                @mouseover="useTarget('target-6-1')"
              >
                <path
                  class="st19"
                  d="M557.2,902.1c-24.4,50.6-43,103.7-55.5,158.5c-12.5,54.7-18.8,110.6-18.9,166.7c0,0,0,0,0,0.1
                            c0,0,0,0,0,0.1h114v-0.1c0.1-46.7,5.3-94,16.1-141.4s26.7-92.3,46.9-134.3L557.2,902.1z"
                />
                <g>
                  <path
                    class="st10"
                    d="M548.5,1076.7c-4.2,0-6.5-2.3-6.5-8.2c0-5.6,2.6-8.8,7.6-8.8c1.5,0,2.8,0.2,4,0.6l-0.3,3.7
            c-1-0.4-2-0.5-3.1-0.5c-2.3,0-3.3,0.9-3.7,2.7l0,0c0.8-0.4,1.7-0.7,2.8-0.7c3.4,0,5.3,1.7,5.3,5
            C554.6,1074.8,552.3,1076.7,548.5,1076.7z M548.4,1068.8c-0.5,0-1.5,0.3-1.8,0.8c0,2.7,0.6,3.4,1.9,3.4c1.1,0,1.7-0.7,1.7-2.5
            C550.2,1069.4,549.7,1068.8,548.4,1068.8z"
                  />
                  <path
                    class="st10"
                    d="M558.4,1076.7c-1.7,0-2.4-0.6-2.4-2.2c0-1.4,0.7-2.2,2.4-2.2s2.4,0.8,2.4,2.2
            C560.8,1075.9,560.1,1076.7,558.4,1076.7z"
                  />
                  <path
                    class="st10"
                    d="M564.9,1073.4h2.1v-8.3l-2.7,1.5l-0.4-3.6l5-3.1h2.7v13.5h2.1v3.1h-8.9v-3.1H564.9z"
                  />
                </g>
              </g>
            </g>
          </g>

          <!-- Stage 7 -->
          <g
            class="stage-main-group"
            :class="{ active: activeMainGroup === 7 }"
            @mouseover="setIsActive(7)"
          >
            <g
              class="funding-group"
              :class="{ active: activeStage === 'target-7-0' }"
              @click="handleClick('target-7-0')"
              @mouseover="useTarget('')"
            >
              <path
                class="st27"
                d="M1232.9,243.9c-222.9,0-439.4,75.8-613.6,214.8l58.3,73.1l90.7,113.7c24.4-18,49.7-34.6,75.9-49.7h0.1
                            c34.9-20.2,71.3-37.7,109-52.5c74.5-29.3,153.7-47.7,235.2-53.8l0,0c68.8-5.1,139.3-1.4,210,11.9l32.3-141.7l20.8-91.1
            C1379.8,252.2,1306.4,243.9,1232.9,243.9z"
              />
              <g>
                <path
                  class="st10"
                  d="M945,381.8l1.7,4.8l9.7-3.4l2.2,6.5l-9.6,3.4l3.6,10.3l-7.9,2.8l-10-28.3l19.4-6.9l2.5,6.8L945,381.8z"
                />
                <path
                  class="st10"
                  d="M984.1,383.5c1.9,5.9-0.5,10-7.5,12.2c-6.9,2.2-11.3,0.1-13.1-5.7l-4.7-14.8l7.3-2.3l4.6,14.7
            c0.6,1.9,1.8,2.5,3.9,1.8c2-0.6,2.6-1.8,2-3.7L972,371l7.4-2.3L984.1,383.5z"
                />
                <path
                  class="st10"
                  d="M1003.5,387.3l-4.1-14.7c-0.6-2.2-1.8-2.7-3.7-2.2c-1.2,0.3-2.3,1.2-2.7,2.2l4.5,16.4l-7.4,2l-6.5-23.4
            l5.2-1.8l1.6,1.6c1.4-1.9,3.3-3.2,5.7-3.8c5-1.4,9.1,1.1,10.7,7l4.1,14.7L1003.5,387.3z"
                />
                <path
                  class="st10"
                  d="M1029.3,380.8l-1.1-1.7c-1.2,1.8-2.9,3.1-5.6,3.8c-6,1.4-10.1-1.8-12.1-10.3c-1.8-7.7,0.2-13,6-14.3
            c2-0.5,4-0.3,5.7,0.6l0.1-0.1l-0.8-3.1l-1.1-4.5l7.4-2.1l7.2,30.4L1029.3,380.8z M1023.8,364.7c-0.8-0.6-2-0.9-3.4-0.6
            c-2.5,0.6-3.1,2.2-2,6.9c1.2,5.1,2.3,6.2,5,5.5c1.5-0.4,2.4-1.3,2.6-2.3L1023.8,364.7z"
                />
                <path
                  class="st10"
                  d="M1038,352c-2.8,0.6-4.3,0.1-4.7-2.1c-0.4-1.9,0.7-3.2,3.5-3.8c2.9-0.6,4.3,0.2,4.7,2.1
            C1041.9,350.2,1040.9,351.4,1038,352z M1039.5,378.6l-5-23.7l7.6-2l5,24.1L1039.5,378.6z"
                />
                <path
                  class="st10"
                  d="M1065.1,373.6l-2.7-15c-0.4-2.2-1.5-2.9-3.5-2.5c-1.3,0.2-2.4,1-2.9,1.9l3,16.7l-7.6,1.4l-4.3-23.9
            l5.4-1.3l1.4,1.7c1.5-1.7,3.6-2.9,6-3.3c5.1-0.9,9,1.9,10.1,8l2.7,15L1065.1,373.6z"
                />
                <path
                  class="st10"
                  d="M1081.6,361c-0.2,0.2-0.3,0.6-0.3,0.8c0.1,0.5,0.5,0.8,1.4,0.7l5.7-0.5c5.6-0.5,8.2,1.3,8.8,5.2
                                c0.7,4.9-2.4,8.5-10.4,9.7c-7.2,1-10.6-1.3-11.1-4.9c-0.2-1.4,0.2-2.7,1.1-3.9c-1.1-0.6-2.1-1.7-2.3-3.3
                                c-0.2-1.7,0.4-3.4,2.3-4.8c-1.8-0.9-3-2.6-3.4-5.3c-0.6-4.4,1.5-8,8.6-9c2.1-0.3,4.4-0.3,6.3,0.3l4-1.8l2.1,3.3l-2.1,2.3
                                c0.2,0.6,0.4,1.4,0.6,2.1c0.7,4.7-1.8,7.9-8.5,8.9C1083.4,360.9,1082.5,361,1081.6,361z M1081,353.8c0.3,1.8,1.1,2.2,2.4,2
                                c1.9-0.3,2.3-0.9,2.1-2.7c-0.3-1.7-0.9-2.2-2.6-2C1081.2,351.4,1080.8,352.1,1081,353.8z M1082.4,368.8c-0.3,0.5-0.4,1-0.4,1.4
                                c0.2,1.3,1.3,1.7,3.9,1.3c4-0.6,4.9-1.3,4.8-2.2c-0.1-0.8-0.6-1.1-2.6-0.9L1082.4,368.8z"
                />
                <path
                  class="st10"
                  d="M1119.5,359.1v0.1l10.1-1l0.5,6.9l-20.2,1.9l-0.5-5.1c6.2-7.6,11-12.7,10.6-16.5c-0.2-1.8-1.3-2.7-4-2.4
            c-2.4,0.2-4.6,1.4-6.6,3.1l-2.5-6.2c2.5-2.1,5.8-3.6,10.1-4c7.1-0.7,10.9,2.7,11.4,8C1128.9,349,1125.1,353.5,1119.5,359.1z"
                />

                <path
                  class="st10"
                  d="M880.5,482.7l1.1,2.3l-15.4,8.2l-14.1-26.5l2.3-1.2l12.9,24.2L880.5,482.7z"
                />
                <path
                  class="st10"
                  d="M893.4,479.8c-6.2,3-10.5,1.3-14.4-6.6s-2.6-12.4,3.6-15.4c6.2-3,10.4-1.2,14.2,6.7
                                C900.7,472.4,899.6,476.7,893.4,479.8z M892.4,477.9c4.7-2.3,5.3-5.7,2.1-12.3c-3.2-6.6-6.3-8.2-11-5.8
                                c-4.8,2.3-5.5,5.8-2.2,12.3C884.6,478.7,887.7,480.2,892.4,477.9z"
                />
                <path
                  class="st10"
                  d="M921.1,466.4l-6.5-14.5c-1.7-3.7-4.4-4.3-8-2.7c-2.1,0.9-4.2,3.1-4.6,5.6l7.6,16.9l-2.2,1l-9.6-21.5
            l1.5-0.8l1.6,2.4h0.1c0.7-2.4,2.7-4.6,5.3-5.8c4.6-2,8.4-0.9,10.6,4.1l6.5,14.6L921.1,466.4z"
                />
                <path
                  class="st10"
                  d="M929.3,451.2c-1.3,0.9-1.8,2-1.4,2.9c0.3,0.9,1.3,1.2,2.8,0.8l5.6-1.6c5.6-1.6,7.9-0.7,9,2.2
            c1.6,3.9,0.3,7.2-6.3,9.9c-5.7,2.3-9.1,1-10.5-2.5c-0.7-1.7-0.5-3.6,0.6-5.5c-1.3,0-2.6-0.6-3.2-2.1c-0.5-1.3,0-3,1.5-4.2V451
                                c-1.9-0.2-3.6-1.4-4.7-4.1c-1.7-4.1-0.5-7.3,4.8-9.4c1.9-0.8,4-1.2,6-0.7l2.9-3l1,0.9l-2,3c0.8,0.7,1.6,1.7,2.2,3.1
                                c1.7,4.1,0.7,7.1-4.8,9.3C931.6,450.8,930.4,451.1,929.3,451.2z M924.9,446.2c1.5,3.7,4,3.6,7,2.4c4-1.6,4.6-3.7,3.4-6.6
                                c-1.4-3.4-3.4-3.9-7-2.4C924.4,441.1,923.6,443.2,924.9,446.2z M931.3,457.2c-1.1,1.6-1.4,3.4-0.8,4.8c1.1,2.6,3.5,3.4,7.8,1.7
                                c5.4-2.2,6.1-4.4,5.1-6.9c-0.8-1.9-2.2-2.5-6.5-1.2L931.3,457.2z"
                />
                <path
                  class="st10"
                  d="M953.5,437.6l0.7,1.8l-9.5,3.6l-0.7-1.8L953.5,437.6z"
                />
                <path
                  class="st10"
                  d="M961.5,421.1l9.1,25.9l-2.5,0.9L959,422l-8.9,3.1l-0.8-2.4l20.2-7.1l0.9,2.4L961.5,421.1z"
                />
                <path
                  class="st10"
                  d="M976.4,431.6c-2.2-7.1-0.4-11.8,5.5-13.7c5.1-1.6,9.6,0.4,11.9,7.6c0.1,0.3,0.2,0.9,0.3,1.2l-15.1,4.8
                                c2.4,7.2,5.6,8.9,11,7.1c2.4-0.7,4.1-1.9,5.7-3.6l0.9,1.9c-1.6,1.7-3.6,3.1-6.2,3.9C983.3,443.2,979.2,440.3,976.4,431.6z
                                M991.6,425.7c-1.9-5.7-5.2-6.9-9-5.8c-4.4,1.4-5.6,4.7-4.2,9.9L991.6,425.7z"
                />
                <path
                  class="st10"
                  d="M1003.4,436.5l-6.5-22.6l1.8-0.7l0.8,2.5l1,3.1h0.1c0.3-3.9,2.3-7.1,5.7-8c0.8-0.2,1.5-0.4,2.2-0.3
            l0.5,2.5c-0.7,0-1.4,0.1-2.2,0.3c-3.8,1.1-6.1,5.6-4.5,11.1l3.3,11.6L1003.4,436.5z"
                />
                <path
                  class="st10"
                  d="M1046.2,425.3l-3.8-15.6c-0.9-3.7-3.5-4.9-6.9-4.1c-2.7,0.7-4.8,2.9-4,6.2l3.9,16.1l-2.3,0.6l-3.9-16.1
                                c-0.9-3.6-3.6-4.4-6.4-3.7c-2.2,0.5-4.2,2.3-4.8,4.4l4.4,18l-2.3,0.6l-5.6-22.9l1.6-0.6l1.1,2.7h0.1c0.8-2.1,2.8-3.9,5.3-4.5
                                c3.3-0.8,5.7,0,7.3,2.3h0.1c0.4-2.8,2.5-4.7,5.5-5.4c4.4-1.1,7.9,0.6,9.2,5.9l3.8,15.6L1046.2,425.3z"
                />
                <path
                  class="st10"
                  d="M1066,393.3l1.9,10.7l12.5-2.3l0.4,2.4l-12.5,2.3l2.5,14l-2.6,0.5l-5.3-29.5l17.4-3.1l0.5,2.5L1066,393.3z"
                />
                <path
                  class="st10"
                  d="M1088.9,388.4c-1.2,0.2-1.9-0.1-2.1-1.4c-0.2-1.1,0.4-1.8,1.6-1.9s1.9,0.3,2.1,1.4
            S1090.1,388.2,1088.9,388.4z M1092,416.7l-3.6-23.2l2.4-0.6l3.6,23.4L1092,416.7z"
                />
                <path
                  class="st10"
                  d="M1117.9,413.1l-2-15.8c-0.5-4-2.9-5.4-6.9-4.9c-2.3,0.3-4.9,1.8-6,4l2.3,18.3l-2.4,0.3l-3-23.4l1.7-0.4
            l0.8,2.8h0.1c1.3-2.1,3.9-3.6,6.7-4c5-0.6,8.3,1.6,9,7l2,15.8L1117.9,413.1z"
                />
                <path
                  class="st10"
                  d="M1140.3,407.5L1140.3,407.5c-1.2,2.3-3.8,4.1-7.1,4.4c-4.3,0.4-7.4-1.4-7.9-6.7c-0.4-4.5,2.1-7.3,7.8-7.8
                                c2.1-0.2,4.3,0,6,0.5l-0.3-2.9c-0.4-4.5-2.4-5.7-6.6-5.3c-2.4,0.2-4.6,0.9-6.5,2.1l-0.5-2.1c2.1-1.2,4.5-2,7.2-2.2
                                c5.7-0.5,8.3,1.6,8.8,7.7l1.4,15.5l-1.8,0.2L1140.3,407.5z M1139.2,399.8c-0.6-0.4-2.8-0.9-5.6-0.6c-4.5,0.4-6.4,2.5-6.1,5.7
                                c0.4,4,2.9,5.1,6,4.8c3.4-0.3,6.3-3,6.1-5.3L1139.2,399.8z"
                />
                <path
                  class="st10"
                  d="M1164.6,409.2l-0.9-15.9c-0.2-4-2.6-5.6-6.6-5.4c-2.3,0.1-5,1.4-6.2,3.6l1.1,18.5l-2.4,0.1l-1.4-23.5
            l1.7-0.3l0.6,2.8h0.1c1.5-2,4.1-3.4,7-3.5c5-0.3,8.2,2.1,8.5,7.6l0.9,15.9L1164.6,409.2z"
                />
                <path
                  class="st10"
                  d="M1187.3,388c-1.5-0.7-3.4-1.2-5.2-1.1c-5.5,0.1-7.6,3-7.4,9.8c0.2,7.6,2.9,10.3,7.9,10.2
                                c2-0.1,3.9-0.7,5.4-1.8l0.3,2.1c-1.4,1.1-3.6,1.9-6.1,1.9c-6.5,0.2-9.8-3.4-10-12.3c-0.2-8,2.6-12,9.4-12.2
                                c2.2-0.1,4.3,0.4,5.9,1.2L1187.3,388z"
                />
                <path
                  class="st10"
                  d="M1196.1,380c-1.2,0-1.9-0.4-1.9-1.7c0-1.1,0.7-1.7,1.9-1.7c1.2,0,1.9,0.6,1.9,1.7
            C1198,379.5,1197.3,380,1196.1,380z M1194.9,408.4l-0.1-23.5l2.4-0.2l0.1,23.7H1194.9z"
                />
                <path
                  class="st10"
                  d="M1221,408.7l0.4-15.9c0.1-4-2.1-5.8-6.1-5.9c-2.3-0.1-5.1,1-6.5,3.1l-0.4,18.5l-2.4-0.1l0.5-23.6l1.7-0.1
            l0.4,2.8h0.1c1.6-1.9,4.4-3,7.2-3c5,0.1,8,2.8,7.9,8.2l-0.4,16H1221z"
                />
                <path
                  class="st10"
                  d="M1235,398.6c-1.6,0.3-2.5,1-2.6,2c-0.1,0.9,0.6,1.6,2.2,2l5.8,0.9c5.8,0.9,7.4,2.8,7.2,5.9
                                c-0.2,4.2-2.8,6.7-9.9,6.2c-6.2-0.3-8.6-3-8.4-6.7c0.1-1.8,1.1-3.5,2.9-4.7c-1.2-0.6-2.1-1.7-2-3.3c0.1-1.4,1.3-2.7,3.1-3.2
                                v-0.1c-1.6-1-2.7-2.8-2.5-5.7c0.2-4.4,2.6-6.8,8.3-6.5c2,0.1,4.2,0.6,5.7,2l3.9-1.5l0.5,1.3l-3.1,1.9c0.5,1,0.7,2.2,0.6,3.7
                                c-0.2,4.4-2.4,6.7-8.3,6.4C1237.2,399.2,1236,399,1235,398.6z M1234.2,404.8c-1.7,1-2.7,2.5-2.8,4c-0.2,2.8,1.7,4.6,6.3,4.8
                                c5.8,0.3,7.4-1.4,7.6-4c0.1-2-0.9-3.2-5.4-3.9L1234.2,404.8z M1233.1,392.2c-0.2,4,2.1,5,5.3,5.1c4.3,0.2,5.7-1.4,5.9-4.5
                                c0.2-3.6-1.4-5-5.3-5.2C1234.9,387.3,1233.3,388.9,1233.1,392.2z"
                />
              </g>
            </g>

            <g
              class="stage-group"
              :class="{ active: activeStage === 'target-7-0' }"
              @click="handleClick('target-7-0')"
              @mouseover="useTarget('')"
            >
              <path
                class="st17"
                d="M1232.5,952.5c21,0,41.4,2.4,61.1,6.8l93.3-409.4c-50.6-11.5-102.4-17.4-154.4-17.4
            c-157.4,0-310.2,53.5-433.2,151.6l261.9,328.3C1108.2,975,1167.7,952.5,1232.5,952.5z"
              />

              <path class="cls-15 st7" d="M1072.2,748.9c-3.9,0-7.1-1-9.7-2.6l.5-2.8c2.6,1.7,5.5,2.6,9.1,2.6,5.4,0,8.2-2.6,8.2-7.1,0-9.1-17.5-5.1-17.5-16.4s3.3-9.1,10.6-9.1,6.3.7,8.7,1.8l-.5,2.7c-2.6-1.1-5.1-1.7-8.1-1.7-5.6,0-7.9,2.9-7.9,6.1,0,8.7,17.6,4.6,17.6,16.4.1,6.3-3.7,10.1-11,10.1Z" />
              <path class="cls-15 st7" d="M1093.9,723.7v18.1c0,3.2,1.7,4.5,4.4,4.5s1.7,0,2.3-.4l.2,2.2c-.7.3-2,.5-3.2.5-4.2,0-6.4-2.2-6.4-6.8v-18.2h-4.2v-2.2h4.2l.5-7.1,2.2-.3v7.3h7.1v2.2h-7.1v.2Z" />
              <path class="cls-15 st7" d="M1122.2,744.5c-1.6,2.5-4.7,4.2-8.5,4.2s-8.2-2.4-8.2-8.4,3.1-8,9.7-8,4.9.5,6.7,1.2v-3.3c0-5.1-2.1-6.7-6.9-6.7s-5.3.5-7.6,1.7l-.3-2.4c2.5-1.2,5.3-1.8,8.4-1.8,6.6,0,9.2,2.7,9.2,9.6v17.7h-2.1l-.4-3.8ZM1121.8,735.7c-.7-.5-3-1.3-6.3-1.3-5.2,0-7.5,2.1-7.5,5.9s2.7,6.1,6.3,6.1,7.4-2.7,7.4-5.3v-5.4Z" />
              <path class="cls-15 st7" d="M1137.3,736.1c-1.8.4-2.8,1.3-2.8,2.5s.8,1.8,2.6,2.1l6.6.7c6.6.7,8.6,2.7,8.6,6.2s-2.7,7.7-10.9,7.7-10-2.8-10-7.1,1-4,3-5.5c-1.4-.6-2.5-1.8-2.5-3.6s1.3-3.1,3.4-3.8h0c-1.9-1.1-3.2-3.1-3.2-6.4,0-5,2.5-7.9,9.1-7.9s4.8.5,6.5,1.9l4.3-2,.6,1.4-3.5,2.3c.6,1.1,1,2.5,1,4.2,0,5-2.3,7.8-9,7.8s-2.6-.1-3.8-.5ZM1136.8,743.2c-1.9,1.2-2.9,3-2.9,4.7,0,3.2,2.2,5.1,7.5,5.1s8.4-2,8.4-5-1.2-3.6-6.4-4l-6.6-.8ZM1134.8,728.9c0,4.5,2.7,5.5,6.3,5.5s6.4-1.9,6.4-5.5-2-5.6-6.4-5.6-6.3,1.9-6.3,5.6Z" />
              <path class="cls-15 st7" d="M1156.4,734c0-8.4,3.6-13,10.7-13s10.3,3.7,10.3,12.4,0,1,0,1.4h-18.1c.1,8.6,3,11.5,9.4,11.5s5.1-.7,7.4-1.9l.4,2.4c-2.3,1.3-4.9,2.1-8.1,2.1-8.5-.1-11.9-4.6-11.9-14.9ZM1174.9,732.8c0-6.8-3.3-9.3-7.7-9.3s-7.6,3.1-7.9,9.3h15.6Z" />
              <path class="cls-15 st7" d="M1221.2,716.7l-15.3,31.6h-3.4l15.7-31.2h-20.3v-2.9h23.2v2.5Z" />
              <path class="cls-15 st7" d="M1062.8,768.8h9.7v34.1h-9.7v-34.1Z" />
              <path class="cls-15 st7" d="M1108.1,802.9v-17.4c0-2.4-1.2-3.5-3.2-3.5s-3.2,1.1-3.2,3.4v17.5h-8.8v-17.5c0-2.4-1.1-3.4-3.2-3.4s-2.5.6-3.2,1.5v19.3h-8.7v-27.6l6.3-.4,1.4,2.4c2-1.7,4.5-2.7,7-2.7s5.6,1.1,7.2,3.5c2-2.5,4.7-3.5,7.8-3.5,5.6,0,9.5,3.6,9.5,10.8v17.6h-8.9Z" />
              <path class="cls-15 st7" d="M1135.8,803.3c-2.2,0-4.2-.5-5.8-1.9h0v3.7c0,0,0,4.8,0,4.8h-8.8v-34.8l6.1-.4,1.6,2.2c1.9-1.6,4.5-2.5,7.1-2.5,6.9,0,10.6,3.8,10.6,14.3s-4.3,14.6-10.8,14.6ZM1133.6,782c-1.5,0-2.9.7-3.6,1.8v10.7c.7,1.1,1.9,1.9,3.5,1.9,2.5,0,4-1.2,4-7.5s-1-6.9-3.9-6.9Z" />
              <path class="cls-15 st7" d="M1150.3,802.9v-35.2l8.9-.4v35.6h-8.9Z" />
              <path class="cls-15 st7" d="M1163,788.8c0-8.9,4-14.2,12.7-14.2s11.8,4.2,11.8,13,0,2.8-.2,3.9h-15.2c.4,3.4,2.4,4.6,6.5,4.6s5-.4,7.3-1.1l1,6.7c-2.3,1-5.7,1.7-9.4,1.7-10,0-14.5-5.2-14.5-14.6ZM1179.5,786c0-3.2-1.4-4.4-3.8-4.4s-3.6,1-3.9,4.4h7.7Z" />
              <path class="cls-15 st7" d="M1221.3,802.9v-17.4c0-2.4-1.2-3.5-3.2-3.5s-3.2,1.1-3.2,3.4v17.5h-8.8v-17.5c0-2.4-1.1-3.4-3.2-3.4s-2.5.6-3.2,1.5v19.3h-8.7v-27.6l6.3-.4,1.4,2.4c2-1.7,4.5-2.7,7-2.7s5.6,1.1,7.2,3.5c2-2.5,4.7-3.5,7.8-3.5,5.6,0,9.5,3.6,9.5,10.8v17.6h-8.9Z" />
              <path class="cls-15 st7" d="M1233.3,788.8c0-8.9,4-14.2,12.7-14.2s11.8,4.2,11.8,13-.1,2.8-.2,3.9h-15.2c.4,3.4,2.4,4.6,6.5,4.6s5-.4,7.3-1.1l1,6.7c-2.3,1-5.7,1.7-9.4,1.7-10,0-14.5-5.2-14.5-14.6ZM1249.8,786c-.1-3.2-1.4-4.4-3.8-4.4s-3.6,1-3.9,4.4h7.7Z" />
              <path class="cls-15 st7" d="M1277,802.9v-17.4c0-2.5-1.1-3.5-3.4-3.5s-2.9.6-3.6,1.6v19.3h-8.7v-27.7l6.3-.4,1.2,2.2c2-1.6,4.6-2.5,7.4-2.5,5.9,0,9.6,3.9,9.6,11v17.4h-8.8Z" />
              <path class="cls-15 st7" d="M1303.3,784.3v6h-13.6v-6h13.6Z" />
              <path class="cls-15 st7" d="M1072,836v12.8c0,1.8.9,2.5,2.9,2.5s1.6-.1,2.1-.3l.5,6.1c-1,.5-3.1.9-5.3.9-6.1,0-9-3.5-9-9v-13h-2.6v-6.3h3.1l1.4-5.9,6.9-.6v6.5h5.8v6.3h-5.8Z" />
              <path class="cls-15 st7" d="M1095.4,855.2c-1.8,1.7-4.3,2.7-7.2,2.7-5.4,0-8.7-3.1-8.7-9.3s3.7-8.8,9.9-8.8,3.4.2,4.7.6v-1c0-2-1.2-3.2-4.6-3.2s-5,.5-7.6,1.4l-.7-6.6c2.9-1.2,6.2-1.8,9.9-1.8,8.8,0,11.6,3.8,11.6,11.5v16.8h-6.7l-.6-2.3ZM1094.1,846c-.6-.2-1.6-.4-3-.4-2.3,0-3.4,1-3.4,3s1.1,2.8,2.9,2.8,3.5-1,3.5-2.5v-2.9Z" />
              <path class="cls-15 st7" d="M1116.9,836v12.8c0,1.8.9,2.5,2.9,2.5s1.6-.1,2.1-.3l.5,6.1c-1,.5-3.1.9-5.3.9-6.1,0-9-3.5-9-9v-13h-2.6v-6.3h3.1l1.4-5.9,6.9-.6v6.5h5.8v6.3h-5.8Z" />
              <path class="cls-15 st7" d="M1130.8,827.6c-3.3,0-4.8-.9-4.8-3.4s1.5-3.4,4.8-3.4,4.7,1.2,4.7,3.4-1.4,3.4-4.7,3.4ZM1126.3,857.5v-27.6l9-.4v28h-9Z" />
              <path class="cls-15 st7" d="M1152.5,858.1c-9.1,0-13.1-4.2-13.1-14.4s4.1-14.6,13.2-14.6,13.1,4.4,13.1,14.6-4,14.4-13.2,14.4ZM1152.5,851.3c3,0,4-1.5,4-7.6s-1-7.6-4-7.6-4.1,1.4-4.1,7.6,1.1,7.6,4.1,7.6Z" />
              <path class="cls-15 st7" d="M1184.8,857.5v-17.4c0-2.5-1.1-3.5-3.4-3.5s-2.9.6-3.6,1.6v19.3h-8.7v-27.7l6.3-.4,1.2,2.2c2-1.6,4.6-2.5,7.4-2.5,5.9,0,9.6,3.9,9.6,11v17.4h-8.8Z" />
            </g>

            <g class="target-group">
              <g
                id="target-7-2"
                class="target-item"
                :class="{ active: activeStage === 'target-7-2' }"
                @mouseover="useTarget('target-7-2')"
              >
                <path
                  class="st20"
                  d="M1185.3,593.8l-8.5-113.7c-37.3,2.8-74.4,8.3-110.8,16.7s-72.3,19.3-107.1,33l41.6,106.2
            C1059,613,1121.2,598.5,1185.3,593.8z"
                />
                <g>
                  <path
                    class="st10"
                    d="M1072.2,551.3l-5.7,12.9h-5.1l6.4-12.4h-8l0.2-4.1h12.1L1072.2,551.3L1072.2,551.3z"
                  />
                  <path
                    class="st10"
                    d="M1076.2,564.4c-1.7,0-2.4-0.6-2.4-2.2c0-1.4,0.7-2.2,2.4-2.2s2.4,0.8,2.4,2.2
            C1078.6,563.6,1077.9,564.4,1076.2,564.4z"
                  />
                  <path
                    class="st10"
                    d="M1086.5,560.3L1086.5,560.3l5.6,0.1l-0.1,3.8h-11.2v-2.8c3.8-3.9,6.7-6.4,6.7-8.5c0-1-0.6-1.5-2.1-1.5
            c-1.3,0-2.6,0.6-3.8,1.3l-1.1-3.5c1.5-1,3.4-1.7,5.7-1.7c4,0,5.9,2.1,5.9,5C1092.2,555.2,1089.9,557.5,1086.5,560.3z"
                  />
                </g>
              </g>

              <g
                id="target-7-1"
                class="target-item"
                :class="{ active: activeStage === 'target-7-1' }"
                @mouseover="useTarget('target-7-1')"
              >
                <path
                  class="st21"
                  d="M958.9,529.8c-17.4,6.8-34.6,14.3-51.4,22.4c-16.9,8.1-33.4,16.8-49.6,26.2h-0.1
                            c-16.2,9.4-32.1,19.3-47.5,29.8s-30.3,21.5-44.8,33.1l0,0c-0.1,0-0.1,0.1-0.2,0.1l-0.1,0.1l71.2,89.2c0.1-0.1,0.2-0.1,0.3-0.2
                            l0,0c24.8-19.7,50.9-37.5,78.3-53.3l0,0c27.4-15.8,56-29.6,85.6-41.2L958.9,529.8z"
                />
                <g>
                  <path
                    class="st10"
                    d="M883.2,625.2l-5.7,12.9h-5.1l6.4-12.4h-8l0.2-4.1h12.1L883.2,625.2L883.2,625.2z"
                  />
                  <path
                    class="st10"
                    d="M887.2,638.3c-1.7,0-2.4-0.6-2.4-2.2c0-1.4,0.7-2.2,2.4-2.2s2.4,0.8,2.4,2.2
            C889.6,637.5,888.9,638.3,887.2,638.3z"
                  />
                  <path
                    class="st10"
                    d="M893.7,635h2.1v-8.3l-2.7,1.5l-0.4-3.6l5-3.1h2.7V635h2.1v3.1h-8.9V635H893.7z"
                  />
                </g>
              </g>

              <g
                id="target-7-3"
                class="target-item"
                :class="{ active: activeStage === 'target-7-3' }"
                @mouseover="useTarget('target-7-3')"
              >
                <path
                  class="st22"
                  d="M1399.5,496.9c-36.4-8.3-73.3-13.9-110.6-16.7c-37.3-2.8-74.8-2.8-112.1-0.1l8.5,113.7
            c61.8-4.6,125.2-0.1,188.7,14.4L1399.5,496.9z"
                />
                <g>
                  <path
                    class="st10"
                    d="M1282.4,535.8l-5.7,12.9h-5.1l6.4-12.4h-8l0.2-4.1h12.1L1282.4,535.8L1282.4,535.8z"
                  />
                  <path
                    class="st10"
                    d="M1286.4,549c-1.7,0-2.4-0.6-2.4-2.2c0-1.4,0.7-2.2,2.4-2.2s2.4,0.8,2.4,2.2
            C1288.7,548.1,1288,549,1286.4,549z"
                  />
                  <path
                    class="st10"
                    d="M1295.8,548.9c-2,0-3.8-0.5-5-1.2l0.8-3.4c1.3,0.6,2.6,1,4.1,1c1.6,0,2.4-0.6,2.4-1.7c0-1-0.6-1.8-3.9-1.8
                                v-3.1c3.1,0,3.7-0.6,3.7-1.7c0-0.8-0.6-1.3-2.2-1.3s-2.9,0.5-4.1,1.3l-1-3.4c1.3-1,3.4-1.7,5.4-1.7c3.8,0,6.1,1.8,6.1,4.8
                                c0,1.5-0.7,2.7-2.1,3.4l0,0c1.5,0.6,2.4,1.8,2.4,3.6C1302.5,546.9,1300.4,548.9,1295.8,548.9z"
                  />
                </g>
              </g>
            </g>
          </g>

          <use
            ref="fakeIndex"
            class="fake-z-index"
            :xlink:href="'#' + hoveringTarget"
            @click="handleClick(hoveringTarget)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script setup>
// eslint-disable no-useless-concat
const activeStage = ref(null);
const hoveringTarget = ref('');
const activeMainGroup = ref(0);
const clickedMainGroup = ref(0);
const mouseOnSVG = ref(false);
const showSlider = ref(false);

watch(
    () => activeStage.value,
    (newValue) => {
        if (newValue === null) {
            showSlider.value = false;
        }
        if (newValue !== null) {
            setTimeout(() => {
                showSlider.value = true;
            }, 300);
        }
    },
);

watch(
    () => mouseOnSVG.value,
    (newValue) => {
        if (newValue === false) {
            activeMainGroup.value = parseInt(clickedMainGroup.value, 10);
            hoveringTarget.value = activeStage.value;
        }
    },
);

const { locale } = useI18n();
const storyblokApi = useStoryblokApi();

const {
    data: participationWheelData,
    status: participationWheelDataStatus,
    error: participationWheelDataError,
} = useLazyAsyncData(
    'get-data-participation-wheel',
    async () => storyblokApi.get('cdn/stories/participationprocess/participationprocess', {
        version: 'published',
        resolve_relations: 'News.department,News.tags,News.author,News.type',
        resolve_links: 'link',
        language: locale.value,
    }),
    {
        transform: (res) => {
            const sliderContentMounted = [];
            res.data.story.content.stages.forEach((stage) => {
                const sliderObject = {};
                sliderObject.stage = stage.id.slice(0, -2);
                sliderObject.stageId = stage.id;
                sliderObject.title = stage.name;
                sliderObject.actors = stage.card[0]?.actors;
                sliderObject.text = stage.card[0]?.text;
                sliderObject.extraBox = stage.extra_title
                    ? `<b>${stage.extra_title}</b> <br />${stage.extra_text}`
                    : '';
                sliderContentMounted.push(sliderObject);
                stage.phases.forEach((phase) => {
                    const sliderObjectPhase = {};
                    sliderObjectPhase.stageId = phase.id;
                    sliderObjectPhase.title = phase.name;
                    sliderObjectPhase.actors = phase.card[0]?.actors;
                    sliderObjectPhase.text = phase.card[0]?.text;
                    sliderContentMounted.push(sliderObjectPhase);
                });
            });

            return sliderContentMounted;
        },
    },
);

/*
    Error handling
*/
watch(
    () => participationWheelDataError.value,
    (error) => {
        if (error) {
            useSentryError(error, {
                version: 'published',
                resolve_relations: 'News.department,News.tags,News.author,News.type',
                language: locale.value,
            });
        }
    },
);

const handleClick = (value) => {
    activeStage.value = value;
    clickedMainGroup.value = activeMainGroup.value;
};

const closeSlider = () => {
    activeStage.value = null;
    clickedMainGroup.value = 0;
};

const fakeIndex = ref(null);
const useTarget = (id) => {
    fakeIndex.value.style.transition = 'transform 0s ease-in-out, opacity 0s ease-in-out;';
    fakeIndex.value.style.transform = 'scale(1.3)';

    window.setTimeout(() => {
        hoveringTarget.value = id;
        fakeIndex.value.style.transition = 'transform 0.5s ease-in-out, opacity 0.2s ease-in-out;';
        fakeIndex.value.style.transform = 'scale(1.175)';
    }, 100);
};

const setIsActive = (index) => {
    activeMainGroup.value = parseInt(index, 10);
};

const setNewActiveStage = (event) => {
    activeStage.value = `target-${event}`;
    hoveringTarget.value = `target-${event}`;
    clickedMainGroup.value = event.substring(0, 1);
    activeMainGroup.value = parseInt(event.substring(0, 1), 10);
};
</script>

<style lang="scss">
.fake-z-index {
    @media (hover: hover) {
        cursor: pointer;
        opacity: 0;
        transform-box: fill-box;
        transform-origin: center;
        transition: transform 0.25s ease-in-out, opacity 0.2s ease-in-out;

        .svg-elements-wrapper:hover & {
            opacity: 1;
        }

        /* stylelint-disable-next-line no-descending-specificity */
        &:hover {
            transform: scale(1.2);
        }
    }
}

.participation-process__wrapper {
    display: grid;
    max-width: 1404px;
    padding-right: 25px;
    padding-left: 25px;
    margin: 0 auto;

    @include fluid('margin-bottom', 50px, 100px);
    @include fluid('margin-top', 50px, 100px);

    column-gap: 36px;
    grid-template-columns: repeat(18, 1fr);

    @include mobile {
        overflow: hidden;
        grid-template-columns: repeat(3, 1fr);
    }
}

.stage-main-group {
    cursor: pointer;

    @media (hover: hover) {
        &.active {
            .funding-group {
                transform: scale(0.98) scaleX(0.998);
            }

            #fg-stage-4-0 {
                transform: scale(0.98) scaleX(0.998);
            }

            .target-group {
                opacity: 1;
            }
        }
    }
}

.stage-group {
    &:hover {
        .st1 {
            // fill: darken($color: #e8e9d5, $amount: 5);
        }
        .st3 {
            /* stylelint-disable-next-line scss/no-global-function-names */
            fill: darken($color: #f2e2ea, $amount: 5);
        }
        .st8 {
            /* stylelint-disable-next-line scss/no-global-function-names */
            fill: darken($color: #e1bccd, $amount: 5);
        }
        .st5 {
            /* stylelint-disable-next-line scss/no-global-function-names */
            fill: darken($color: #5e404d, $amount: 5);
        }
        .st12 {
            /* stylelint-disable-next-line scss/no-global-function-names */
            fill: darken($color: #f0f1e4, $amount: 5);
        }
        .st16 {
            /* stylelint-disable-next-line scss/no-global-function-names */
            fill: darken($color: #aeb167, $amount: 5);
        }
        .st17 {
            /* stylelint-disable-next-line scss/no-global-function-names */
            fill: darken($color: #dee8ec, $amount: 5);
        }
    }
}

.target-group {
    cursor: pointer;
    opacity: 0;
    transform-origin: center;
    transition: opacity 0.2s ease-in-out;
}

.target-item {
    cursor: pointer;
    transform-box: fill-box;
    transform-origin: center;

    g {
        pointer-events: none;
    }

    &.active {
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.34);

        path {
            transition: fill 0.25s ease-in-out;
        }

        g {
            path {
                transition: fill 0.25s ease-in-out;
            }
        }
    }
}

.funding-group {
    cursor: pointer;
    transform: scale(0.93) scaleX(1);
    transform-origin: center;
    transition: transform 0.3s ease-in-out;
}

.participation-process {
    display: flex;
    max-width: 100%;
    margin-top: -173px;
    margin-bottom: -184px;
    grid-column: 1 / span 18;

    @include tablet {
        margin-top: -147px;
        margin-bottom: -156px;
    }

    @include tablet-portrait {
        margin-top: -121px;
        margin-bottom: -127px;
    }

    @include mobile {
        padding-top: 81px;
        margin-top: -20px;
        margin-bottom: 54px;
        grid-column: 1 / span 3;
    }
}
.participation-process__info {
    width: 0;
    flex-basis: 0%;
    flex-grow: 0;
    transition: flex-basis 0.3s $EASE_OUT--CIRC, width 0.3s $EASE_OUT--CIRC;

    .showContent & {
        width: 50%;
        max-width: 680px;
        flex-basis: 50%;
        margin-top: 150px;
        margin-bottom: 220px;

        @include mobile {
            width: 100%;
            max-width: 100%;
            flex-basis: 100%;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &--inner {
        position: relative;
        background: var(--c-white);
        box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.1);
        opacity: 1;
        transition: opacity 0.6s $EASE_OUT--CIRC;

        .showContent & {
            width: 100%;
            opacity: 1;
            transition-delay: 0.3s;
        }
    }
}
.participation-process__wheel {
    height: 100%;
    flex-basis: 100%;
    transition: flex-basis 0.6s $EASE_OUT--CIRC;

    @include mobile {
        transform: translateX(0) scale(1.6);
    }

    .showContent & {
        display: block;
        flex-basis: 50%;
        transform: translateX(100px) scale(1.5);

        @include mobile {
            display: none;
        }

        @media screen and (max-width: 1900px) {
            transform: translateX(0) scale(1.3);
        }
        @media screen and (max-width: 1554px) {
            transform: translateX(0) scale(1);
        }
    }
}

.participation-process__info-close {
    @include z-index('close');

    position: absolute;
    top: 10px;
    right: 10px;
    color:  var(--c-coral-1);
    cursor: pointer;

    @media (hover: hover) {
        top: 40px;
        right: 40px;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    path {
        fill: var(--c-coral-4);
        stroke: var(--c-coral-4);
    }
}

.st0 {
    fill: #e8e9d5;
}
/* stylelint-disable-next-line no-descending-specificity */
.st1 {
    fill: #f5e6d6;
}
.st2 {
    fill: #f5e3e0;
}
/* stylelint-disable-next-line no-descending-specificity */
.st3 {
    fill: #f2e2ea;
}
.st4 {
    fill: #3c2c34;
}
/* stylelint-disable-next-line no-descending-specificity */
.st5 {
    fill: #5e404d;
}
.st6 {
    fill: #fbf5f8;

    #fg-stage-4-0.active & {
        fill: #3c2f23;
    }
}
.st7 {
    fill: #1e373e;
}
/* stylelint-disable-next-line no-descending-specificity */
.st8 {
    fill: #e1bccd;
}
.st9 {
    fill: #cf8cab;

    .active > & {
        fill: #fbf5f8;
    }
}
.st10 {
    fill: #f3f6f8;

    #fg-stage-4-0.active & {
        fill: #3c2f23;
    }
}
.st11 {
    fill: #ae4f7d;

    .active > & {
        fill: #fbf5f8;
    }
}
/* stylelint-disable-next-line no-descending-specificity */
.st12 {
    fill: #f0f1e4;
}
.st13 {
    fill: #383625;
}
.st14 {
    fill: #cecfa0;

    .active > & {
        fill: #f8f8f2;
    }
}
.st15 {
    fill: #dbdcbb;

    .active > & {
        fill: #f8f8f2;
    }
}
/* stylelint-disable-next-line no-descending-specificity */
.st16 {
    fill: #aeb167;
}
/* stylelint-disable-next-line no-descending-specificity */
.st17 {
    fill: #dee8ec;
}
.st18 {
    fill: #56552e;

    .active > & {
        fill: #f8f8f2;
    }
}
.st19 {
    fill: #7d8430;

    .active > & {
        fill: #f8f8f2;
    }
}
.st20 {
    fill: #a4b6bc;

    .active > & {
        fill: #f3f6f8;
    }
}
.st21 {
    fill: #d0dbe0;

    .active > & {
        fill: #f3f6f8;
    }
}
.st22 {
    fill: #3b5c65;

    .active > & {
        fill: #f3f6f8;
    }
}
.st23 {
    fill: #3c2f23;

    .funding-group.active & {
        fill: #fcf7f1;
    }
}
.st24 {
    fill: #d6a263;

    .active > & {
        fill: #fcf7f1;
    }
}
.st25 {
    fill: #e6c69c;

    .active > & {
        fill: #f3f6f8;
    }
}
.st26 {
    fill: #5d472a;

    .active > & {
        fill: #f3f6f8;
    }
}
.st27 {
    fill: #1e373e;

    .active > & {
        fill: #f3f6f8;
    }
}
/* stylelint-disable-next-line no-duplicate-selectors */
.funding-group {
    &.active {
        /* stylelint-disable-next-line no-descending-specificity */
        .st10 {
            fill: #1e373e;
        }
    }
}
#target-2-1,
#target-2-2 {
    &.active {
        .st10 {
            fill: #3c2c34;
        }
    }
}
#target-4-1,
#target-4-2,
#target-4-3 {
    &.active {
        .st10 {
            fill: #3c2f23;
        }
    }
}
#target-5-1,
#target-5-2 {
    &.active {
        .st10 {
            fill: #383625;
        }
    }
}
#target-6-1,
#target-6-2 {
    &.active {
        .st10 {
            fill: #383625;
        }
    }
}
#target-7-1,
#target-7-2,
#target-7-3 {
    &.active {
        .st10 {
            fill: #1e373e;
        }
    }
}

.cls-1 {
    fill: #383625;
    transform: translate(-24px, -5px);
}

.cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16, .cls-17, .cls-18 {
    stroke-width: 0px;
}

.cls-2 {
    fill: #e8e9d5;
}

.cls-3 {
    fill: #f3f6f8;
}

.cls-4 {
    fill: #fbf5f8;
}

.cls-5 {
    fill: #dde7ec;
}

.cls-6 {
    fill: #faf5f9;
    transform: translate(-31px, 10px);
}

.cls-7 {
    fill: #fdf8f2;
}

.cls-8 {
    fill: #3c2c34;
    transform: translate(-20px, 8px);
}

.cls-9 {
    fill: #b4b752;
}

.cls-10 {
    fill: #3c2f23;
    transform: translate(-40px, 15px);
}

.cls-11 {
    fill: #5e404d;
}

.cls-12 {
    fill: #f2e3ea;
}

.cls-13 {
    fill: #f0f1e4;
}

.cls-14 {
    fill: #f6e6d5;
}

.cls-15 {
    fill: #1e373e;
    transform: translate(-40px, -30px);
}

.cls-16 {
    fill: #daa9c0;
}

.cls-17 {
    fill: #383523;
    transform: translate(-21px, -2px);
}

.cls-18 {
    fill: #1e373e;
    transform: translate(-34px, 0px);
}
</style>
