<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    data-lenis-prevent
    class="block-participation-process-slider-element"
  >
    <div class="block-participation-process-slider-element__stage">
      Stage: {{ stage.replace('-', '.') }}
    </div>
    <h3 class="block-participation-process-slider-element__title">
      {{ title }}
    </h3>
    <div class="block-participation-process-slider-element__actors">
      <div>Actors:</div>
      <div
        v-for="actor in actors.split(',')"
        :key="actor"
        class="block-participation-process-slider-element__actors-element"
      >
        {{ actor }}
      </div>
    </div>
    <p class="block-participation-process-slider-element__text">
      <AtomRichtext
        class="form-fieldset__text"
        :html="richTextResolver.render(text)"
      />
    </p>
    <div
      v-if="extraBox"
      class="block-participation-process-slider-element__extra"
      v-html="extraBox"
    />
  </div>
</template>

<script setup>
defineProps({
    stage: {
        type: String,
        required: true,
    },
    title: {
        type: String,
        required: true,
    },
    actors: {
        type: String,
        required: true,
    },
    text: {
        type: Object,
        required: true,
    },
    extraBox: {
        type: String,
        required: false,
        default: null,
    },
});

const { richTextResolver } = useStoryblokApi();

</script>

<style lang="scss">
.block-participation-process-slider-element {
    @include remove-scrollbars;

    max-width: calc(100% - 190px);
    max-height: 65vh;
    padding: 0 10px;
    margin: 0 auto 50px auto;
    margin-bottom: 20px;
    overflow-y: scroll;

    @include mobile {
        max-width: calc(100% - 40px);
    }

}

.block-participation-process-slider-element__stage {
    color: var(--c-coral-1);
    font-family: var(--f-family--bold);
}

.block-participation-process-slider-element__title {
    margin-bottom: 20px;
    color: var(--c-coral-1);
    font-family: var(--f-family--regular);
    font-size: var(--f-size--h3);
}

.block-participation-process-slider-element__actors {
    margin-bottom: 30px;
    div:first-child {
        margin-bottom: 5px;
        color: var(--c-coral-1);
        font-family: var(--f-family--bold);
    }
}

.block-participation-process-slider-element__actors-element {
    display: inline-flex;
    min-height: 30px;
    align-items: center;
    padding: 3px 30px 0 30px;
    border-radius: 4px;
    margin-right: 13px;
    margin-bottom: 13px;
    background-color: var(--c-coral-13);
    color: var(--c-coral-1);
    font-family: var(--f-family--regular);
}

.block-participation-process-slider-element__text {
    margin-bottom: 30px;
    color: var(--c-coral-1);
}

.block-participation-process-slider-element__extra {
    padding: 27px;
    background-color: var(--c-coral-1);
    color: var(--c-white);
    font-family: var(--f-family--regular);
    font-size: var(--f-size--p);
    line-height: 28px;
}
</style>
